import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import api from '../../../services/api';
import { toast } from 'react-toastify';
import { getUserId } from '../../../services/api/utils';
import { Link, useParams } from 'react-router-dom';
import { Button } from '@mui/material';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { IconButton, InputAdornment } from '@mui/material';

const ProfileEdit = () => {
    const [showPassword, setShowPassword] = useState(false);
    const [validationErrors, setValidationErrors] = useState({});
    const { id } = useParams();
    console.log("id============", id);
    const [formData, setFormData] = useState({
        name: '',
        mobile: '',
        email: '',
        password: ''
    });

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData(prevState => ({
            ...prevState,
            [name]: value
        }));
        setValidationErrors({
            ...validationErrors,
            [name]: ''
        });
    };

    const fetchData = async () => {
        try {
            const response = await api.getUserById(id);
            const res = response?.data
            if (res) {
                const responseData = res;
                setFormData((prevState) => ({
                    ...prevState,
                    name: responseData?.name,
                    mobile: responseData?.mobile || "",
                    email: responseData?.email || "",
                    // password: '',
                }));
            }
            console.log("res", res);
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };

    const handleSubmit = async (e) => {
        const errors = {};

        if (!formData.name.trim()) {
            errors.name = "Name should not be empty or only spaces";
        }
        if (Object.keys(errors).length > 0) {
            setValidationErrors(errors);
            return;
        }
        try {
            const update = await api.updateProfile(formData);
            if (update.response_code === 200) {
                toast.success("Updated Successfully");
                fetchData();
            }
        } catch (error) {
            toast.error("Failed to Update Profile");
        }
    };

    useEffect(() => {
        fetchData();
    }, []);

    const togglePasswordVisibility = () => {
        setShowPassword(!showPassword);
    };

    return (
        <>
            <h1 className='mb-5 text-[20px] font-[600]'>Edit User</h1>
            <div className='grid grid-cols-1'>
                <Box
                    sx={{
                        '& > :not(style)': { m: 1, width: '100%' },
                    }}
                    noValidate
                    autoComplete="off"
                >
                    <label>Full Name</label>
                    <TextField
                        name="name"
                        value={formData?.name}
                        onChange={handleInputChange}
                        variant="outlined"
                        error={!!validationErrors.name}
                        helperText={validationErrors.name}
                    />

                    <label>Mobile</label>
                    <TextField
                        name="mobile"
                        value={formData?.mobile}
                        onChange={handleInputChange}
                        variant="outlined"
                    />

                    <label>Email</label>
                    <TextField
                        name="email"
                        value={formData?.email}
                        onChange={handleInputChange}
                        variant="outlined"
                    />

                    <label>Password(Leave blank to keep the same password)</label>
                    <TextField
                        name="password"
                        // value={formData?.password}
                        onChange={handleInputChange}
                        variant="outlined"
                        type={showPassword ? 'text' : 'password'}
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    <IconButton onClick={togglePasswordVisibility}>
                                        {showPassword ? <VisibilityOff /> : <Visibility />}
                                    </IconButton>
                                </InputAdornment>
                            ),
                        }}
                    />
                </Box>
            </div>
            <div className='flex items-center justify-center gap-3'>
                <Button variant='outlined' color='primary' type="button" onClick={handleSubmit}>
                    Save
                </Button>
                <Link to="/admin/profile">
                    <Button variant='outlined' color='secondary' type="button">Cancel</Button>
                </Link>
            </div>
        </>
    );
};

export default ProfileEdit;
