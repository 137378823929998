import React from 'react'
import { Link } from 'react-router-dom'

export default function Footer() {
  return (
    <div className="fixed-bottom bg-dark p-3 text-center">
        <p className="text-light flex justify-center gap-7 mb-0">
            <Link to="/login"><p className="text-light">Sign in</p></Link>
            {' | '}
            <Link to="/terms-and-conditions"><p className="text-light">Terms and Condition</p></Link>
            {' | '}
            <Link to="/privacy-policy"><p className="text-light">Privacy Policy</p></Link>
            {' | '}
            <Link to="/refund-policy"><p className="text-light">Refund Policy</p></Link>
        </p>
    </div>
  )
}
