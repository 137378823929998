import React, { useEffect, useState } from 'react'
import AddIcon from "@mui/icons-material/Add";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import api from '../../../services/api';
import { toast } from 'react-toastify';
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

const LotLevel = () => {
  const [add, setAdd] = useState(false);
  const [edit, setEdit] = useState(false);
  const [getData, setGetData] = useState([]);
  const [lotData, setLotData] = useState([]);
  const [editingLotId, setEditingLotId] = useState(null);
  const [disable, setDisable] = useState(false);
  const [indx, setIndx] = useState(null);
  const [responseData, setResponseData] = useState([]);
  const [validationErrors, setValidationErrors] = useState({});
  const [data, setData] = useState({
    lotName: "",
    is_disable: false,
  });

  const getAllData = async () => {
    try {
      const response = await api.getAllTableData();
      console.log("response-------------------------");
      if (response) {
        setGetData(response?.data);
      } else {
        console.error("Failed to fetch data");
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    getAllData();
    fetchLotData();
  }, []);

  const handleOpenAdd = () => setAdd(true);
  const handleCloseAdd = () => {
    setAdd(false);
    setValidationErrors({});
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    setData({
      ...data,
      [name]: value
    });
    setValidationErrors({
      ...validationErrors,
      [name]: ''
    });
  };

  const resetForm = () => {
    setData({
      lotName: ""
    });
    setValidationErrors({});
  };

  const handleAddLot = async () => {
    const errors = {};

    if (!data.lotName.trim()) {
      errors.lotName = "Lot Name should not be empty or only spaces";
    }
    if (Object.keys(errors).length > 0) {
      setValidationErrors(errors);
      return;
    }
    const apiRes = await api.createLot(data);
    if (apiRes.response_code === 200) {
      toast.success("Data added");
      handleCloseAdd();
      resetForm();
      fetchLotData();
    }
  };

  const handleOpenEdit = async (lotId) => {
    // Find the lot to edit based on the provided lotId
    try {
      const response = await api.getLot(lotId);
      console.log("response----------123=========", response);
      if (response) {
        console.log("Lot data:", response?.data);
        setLotData(response?.data)
        setEditingLotId(lotId); // Store the ID of the lot being edited
        setData({
          lotName: response?.data?.lotName
        });
        setEdit(true); // Open the edit dialog
      } else {
        console.error("Failed to fetch data");
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const handleCloseEdit = () => {
    setEdit(false);
    setEditingLotId(null);
    resetForm();
  };

  const handleEditLot = async () => {
    if (!data.lotName.trim()) {
      setValidationErrors({
        lotName: 'Lot Name should not be empty or only spaces'
      });
      return;
    }
    if (editingLotId) {
      try {
        const update = await api.updateLot(data, editingLotId);
        if (update.response_code === 200) {
          toast.success("Updated Successfully");
          handleCloseEdit();
          fetchLotData();
        }
      } catch (error) {
        toast.error("Failed to Update Lot");
      }
    }
  };

  const handleOpenDisable = (index, lotId) => {
    setIndx(index);
    setEditingLotId(lotId);
    setDisable(true);
  };
  const handleCloseDisable = () => setDisable(false);

  const toggleIsDisable = async (index, id) => {
    const updatedLotData = [...responseData];
    console.log("responseData-----------", responseData);
    updatedLotData[index].is_disable = !updatedLotData[index].is_disable; // Toggle the is_disable property
    setResponseData(updatedLotData); // Update the state with the modified array

    const lotStatus = updatedLotData[index].is_disable ? "disabled" : "enabled";
    try {
      const update = await api.updateLot({ is_disable: updatedLotData[index].is_disable }, id);
      if (update.response_code === 200) {
        toast.success(`Lot ${lotStatus} successfully`);
        handleCloseDisable();
        getAllData();
      }
    } catch (error) {
      toast.error("Failed to Update Lot");
    }
  };

  const fetchLotData = async () => {
    try {
      const apiRes = await api.getLotByUser();
      const res = apiRes?.data
      if (res) {
        setResponseData(res);
      }
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  return (
    <div>
      <div>
        <div className="my-6 ml-auto text-black bg-white rounded-[35px] w-fit flex items-center border border-[#ababab] py-[8px] px-[10px] cursor-pointer"
          onClick={handleOpenAdd}>
          <div>
            <AddIcon className='text-white bg-[#528dfe] rounded-full mr-4 text-[28px]' />
          </div>
          <div className='font-[500]'>
            Add new Lot Level
          </div>
        </div>
        <Dialog
          open={add}
          onClose={handleCloseAdd}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title"></DialogTitle>
          <DialogContent>
            <Typography align="center" sx={{ fontWeight: "600", mb: 4 }}>
              Do you want to add new Lot-Level
            </Typography>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
              <div>
                <b>Lot-Level Name:</b>
              </div>
              <div>
                <TextField
                  id="outlined-basic"
                  variant="outlined"
                  size="small"
                  type="text"
                  name='lotName'
                  value={data.lotName}
                  onChange={handleChange}
                  error={!!validationErrors.lotName}
                  helperText={validationErrors.lotName}
                />
              </div>
            </div>
          </DialogContent>
          <DialogActions className="p-3">
            <Button className='capitalize' variant="outlined" onClick={handleCloseAdd}>
              Cancel
            </Button>
            <Button
              variant="outlined"
              color="success"
              onClick={handleAddLot}
            >
              Add
            </Button>
          </DialogActions>
        </Dialog>
      </div>
      {responseData &&
        responseData.length > 0 &&
        responseData.map((lot, index) => (
          <Accordion className="mb-2 accordian_main" key={index}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography>{lot?.lotName}</Typography>
              <div className="action_menu border-0 ml-auto">
                <div className="gap-2 flex ">
                  <Button
                    onClick={() => handleOpenEdit(lot?.id)}
                    variant="outlined"
                    size="small"
                    color="primary"
                  >
                    Edit
                  </Button>
                  <Button
                    onClick={() => handleOpenDisable(index, lot?.id)}
                    variant="outlined"
                    size="small"
                    color="secondary"
                  >
                    {lot?.is_disable ? "Enable" : "Disable"}
                  </Button>
                </div>
              </div>
            </AccordionSummary>
            <AccordionDetails className="p-0">
              <div className="w-full rounded datatable" style={{ boxShadow: '3px 4px 10px 0px rgba(230, 230, 230, 1)', WebkitBoxShadow: '3px 4px 10px 0px rgba(230, 230, 230, 1)' }}>
                <table className='w-full'>
                  <thead>
                    <tr>
                      <th className="p-3 text-left">Queue Member</th>
                      <th className="p-3 text-left">Token</th>
                      <th className="p-3 text-left">Queue</th>
                      {/* <th className="p-3">Action</th> */}
                    </tr>
                  </thead>
                  <tbody>
                    {getData &&
                      getData.length > 0 &&
                      getData.filter(data => data.lotId === lot.id).map((data, index) => (
                        <tr key={index}>
                          <td className='text-left'>
                            {data?.queueMember?.name}
                          </td>
                          <td className='text-left'>
                            {data?.ticketNumber}
                          </td>
                          <td className='text-left'>
                            {data?.queue?.queueName}
                          </td>
                          {/* <td></td> */}
                        </tr>
                      ))}
                  </tbody>
                </table>
              </div>
            </AccordionDetails>
          </Accordion>
        ))}
      <Dialog
        open={edit}
        onClose={handleCloseEdit}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title"></DialogTitle>
        <DialogContent>
          <Typography align="center" sx={{ fontWeight: "600", mb: 4 }}>
            Do you want to edit Lot-Level
          </Typography>
          <div className="row">
            <div className="col-4 py-1">
              <b>Lot-Level Name:</b>
            </div>
            <div className="col-8 py-1">
              <TextField
                id="outlined-basic"
                variant="outlined"
                size="small"
                name="lotName"
                value={data.lotName}
                onChange={handleChange}
                error={!!validationErrors.lotName}
                helperText={validationErrors.lotName}
              />
            </div>
          </div>
        </DialogContent>
        <DialogActions className="p-3">
          <Button variant="outlined"
            onClick={handleCloseEdit}
          >
            Cancel
          </Button>
          <Button variant="outlined" color="success"
            onClick={handleEditLot}
          >
            Update
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={disable}
        onClose={handleCloseDisable}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title"></DialogTitle>
        <DialogContent>
          <Typography
            id="modal-modal-description"
            sx={{ mt: 2, fontWeight: "600", mb: 2 }}
          >
            <span style={{ color: "red", fontWeight: "400" }}>Note: </span>
            Existing users from the Lot will be Processed as it is.
          </Typography>
          <div className="confirm_but">
            <Button
              variant="outlined"
              onClick={() => toggleIsDisable(indx, editingLotId)}
            >
              Confirm
            </Button>
          </div>
        </DialogContent>
      </Dialog>
    </div>
  )
}

export default LotLevel