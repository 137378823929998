import React, { useEffect, useState } from 'react'
import AddIcon from "@mui/icons-material/Add";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  MenuItem,
  Select,
  TextField,
  Tooltip,
} from "@mui/material";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import { MaterialReactTable } from "material-react-table";
import api from '../../../services/api';
import { toast } from 'react-toastify';
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

const Queues = () => {
  const [getData, setGetData] = useState([]);
  console.log("getData-========", getData);
  const [add, setAdd] = useState(false);
  const [edit, setEdit] = useState(false);
  const [data, setData] = useState({
    queueName: ""
  });
  const [responseData, setResponseData] = useState([]);
  const [selectedLotId, setSelectedLotId] = useState(null);
  console.log("responseData", responseData);
  const [editingQueueId, setEditingQueueId] = useState(null);
  const [disable, setDisable] = useState(false);
  const [indx, setIndx] = useState(null);
  const [queueData, setQueueData] = useState([]);
  const [getQueueData, setGetQueueData] = useState([]);
  const [validationErrors, setValidationErrors] = useState({});
  console.log("queueData--------------==============", queueData);

  const getAllData = async () => {
    try {
      const response = await api.getAllDataInQueue();
      if (response) {
        setGetData(response?.data);
      } else {
        console.error("Failed to fetch admins");
      }
    } catch (error) {
      console.error("Error fetching admins:", error);
    }
  };

  const fetchQueueData = async () => {
    try {
      const apiRes = await api.getAllDataInQueue();
      const res = apiRes?.data
      if (res) {
        setGetQueueData(res);
      }
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  useEffect(() => {
    getAllData();
    fetchQueueData();
  }, []);

  const columns = [
    {
      header: "Queue Name",
      id: "queueName",
      accessorKey: "queue.queueName"
    },
    {
      header: "Queue Owner",
      id: "name",
      accessorKey: "queueOwner.name",
    },
    {
      header: "Lots-Level",
      id: "lotName",
      accessorKey: "lot.lotName",
    },
    {
      header: "Action",
      id: "action",
      accessorKey: "action",
      Cell: ({ row }) => {
        return (
          <div className="gap-2 flex ">
            <Button
              onClick={() => handleOpenEdit(row?.original?.queue?.id)}
              variant="outlined"
              size="small"
              color="primary"
            >
              Edit
            </Button>
            <Button
              onClick={() => handleOpenDisable(row.index, row?.original?.queue?.id)}
              variant="outlined"
              size="small"
              color="secondary"
            >
              {row?.original?.is_disable ? "Enable" : "Disable"}
            </Button>
          </div>
        );
      },
    },
  ];

  const handleOpenAdd = () => setAdd(true);
  const handleCloseAdd = () => {
    setAdd(false);
    setValidationErrors({});
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    setData({
      ...data,
      [name]: value
    });
    setValidationErrors({
      ...validationErrors,
      [name]: ''
    });
  };

  const resetForm = () => {
    setData({
      queueName: ""
    });
    setValidationErrors({});
  };

  const handleAddQueue = async () => {
    const errors = {};

    if (!data.queueName.trim()) {
      errors.queueName = "Queue Name should not be empty or only spaces";
    }
    if (Object.keys(errors).length > 0) {
      setValidationErrors(errors);
      return;
    }
    const apiRes = await api.createQueue({
      ...data,
      lotId: selectedLotId
    });
    if (apiRes.response_code === 200) {
      toast.success("Data added");
      handleCloseAdd();
      resetForm();
      fetchQueueData();
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const apiRes = await api.getLotByUser();
        const res = apiRes?.data
        if (res) {
          setResponseData(res);
          setSelectedLotId(res[0]?.id || null);
        }
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };
    fetchData();
  }, []);

  useEffect(() => {
    const fetchQueue = async () => {
      try {
        const response = await api.getQueue(editingQueueId);
        if (response) {
          setQueueData(response?.data)
        }
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };
    fetchQueue();
  }, []);

  const handleOpenEdit = async (queueId) => {
    // Find the lot to edit based on the provided queueId
    try {
      const response = await api.getQueue(queueId);
      console.log("response----------123=========", response);
      if (response) {
        console.log("queue data:", response?.data);
        // setQueueData(response?.data)
        setEditingQueueId(queueId); // Store the ID of the lot being edited
        setData({
          queueName: response?.data?.queueName
        });
        setEdit(true); // Open the edit dialog
      } else {
        console.error("Failed to fetch data");
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const handleCloseEdit = () => {
    setEdit(false);
    setEditingQueueId(null);
    resetForm();
  };

  const handleEditQueue = async () => {
    const errors = {};

    if (!data.queueName.trim()) {
      setValidationErrors({
        queueName: 'Queue Name should not be empty or only spaces'
      });
      return;
    }
    if (Object.keys(errors).length > 0) {
      setValidationErrors(errors);
      return;
    }
    if (editingQueueId) {
      try {
        const update = await api.updateQueue(data, editingQueueId);
        if (update.response_code === 200) {
          toast.success("Updated Successfully");
          handleCloseEdit();
          fetchQueueData();
        }
      } catch (error) {
        toast.error("Failed to Update Queue");
      }
    }
  };

  const handleOpenDisable = (index, queueId) => {
    setIndx(index);
    setEditingQueueId(queueId);
    setDisable(true);
  };
  const handleCloseDisable = () => setDisable(false);

  const toggleIsDisable = async (index, id) => {
    const updatedQueueData = [...getQueueData];
    updatedQueueData[index].is_disable = !updatedQueueData[index].is_disable; // Toggle the is_disable property
    setGetQueueData(updatedQueueData); // Update the state with the modified array

    const queueStatus = updatedQueueData[index].is_disable ? "disabled" : "enabled";
    try {
      const update = await api.updateQueue({ is_disable: updatedQueueData[index].is_disable }, id);
      if (update.response_code === 200) {
        toast.success(`Queue ${queueStatus} successfully`);
        handleCloseDisable();
        fetchQueueData();
      }
    } catch (error) {
      toast.error("Failed to Update Lot");
    }
  };

  const filteredQueues = getQueueData.filter(queue => queue.lotId === selectedLotId);

  return (
    <div>
      <div className='flex items-center'>
        <div className="action_menu rounded border border-[#ababab]">
          <Box sx={{ minWidth: 120 }}>
            <FormControl fullWidth>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                style={{ maxWidth: "250px" }}
                value={selectedLotId || ""}
                onChange={(event) => setSelectedLotId(event.target.value)}
                renderValue={(selected) => {
                  const selectedLot = responseData.find(lot => lot.id === selected);
                  return selectedLot ? selectedLot.lotName : '';
                }}
              >
                {responseData.map((lot) => (
                  <MenuItem key={lot.id} value={lot.id}>
                    {lot.lotName && lot.lotName.length > 12 ? (
                      <Tooltip title={lot.lotName}>
                        {lot.lotName.substr(0, 12) + "..."}
                      </Tooltip>
                    ) : (
                      lot.lotName
                    )}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Box>

        </div>

        <div className='my-6 ml-auto'>
          <div className="text-black bg-white rounded-[35px] w-fit flex items-center border border-[#ababab] py-[8px] px-[10px] cursor-pointer"
            onClick={handleOpenAdd}>
            <div>
              <AddIcon className='text-white bg-[#528dfe] rounded-full mr-4 text-[28px]' />
            </div>
            <div className='font-[500]'>
              Add new Queue
            </div>
          </div>
          <Dialog
            open={add}
            onClose={handleCloseAdd}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title"></DialogTitle>
            <DialogContent>
              <Typography align="center" sx={{ fontWeight: "600", mb: 4 }}>
                Do you want to add new Queue
              </Typography>
              <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                <div>
                  <b>Queue Name:</b>
                </div>
                <div>
                  <TextField
                    id="outlined-basic"
                    variant="outlined"
                    size="small"
                    type="text"
                    name='queueName'
                    value={data.queueName}
                    onChange={handleChange}
                    error={!!validationErrors.queueName}
                    helperText={validationErrors.queueName}
                  />
                </div>
              </div>
            </DialogContent>
            <DialogActions className="p-3">
              <Button className='capitalize' variant="outlined" onClick={handleCloseAdd}>
                Cancel
              </Button>
              <Button
                variant="outlined"
                color="success"
                onClick={handleAddQueue}
              >
                Add
              </Button>
            </DialogActions>
          </Dialog>
        </div>
      </div>

      {/* <div className='mt-12'>
        {Array.isArray(getData) && (
          <MaterialReactTable
            columns={columns}
            data={getData}
            enableHiding={false}
            enableDensityToggle={false}
          />
        )}
      </div> */}

      {filteredQueues &&
        filteredQueues.length > 0 &&
        filteredQueues.map((queue, index) => (
          <Accordion className="mb-2 accordian_main" key={index}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography>{queue?.queueName}</Typography>
              <div className="action_menu border-0 ml-auto">
                <div className="gap-2 flex ">
                  <Button
                    onClick={() => handleOpenEdit(queue?.id)}
                    variant="outlined"
                    size="small"
                    color="primary"
                  >
                    Edit
                  </Button>
                  <Button
                    onClick={() => handleOpenDisable(index, queue?.id)}
                    variant="outlined"
                    size="small"
                    color="secondary"
                  >
                    {queue?.is_disable ? "Enable" : "Disable"}
                  </Button>
                </div>
              </div>
            </AccordionSummary>
            <AccordionDetails className="p-0">
              <div className="w-full rounded datatable" style={{ boxShadow: '3px 4px 10px 0px rgba(230, 230, 230, 1)', WebkitBoxShadow: '3px 4px 10px 0px rgba(230, 230, 230, 1)' }}>
                <table className='w-full'>
                  <thead>
                    <tr>
                      <th className="p-3 text-left">Queue Owner</th>
                      <th className="p-3 text-left">Lots-Level</th>
                    </tr>
                  </thead>
                  <tbody>
                    {/* {getData &&
                      getData.length > 0 &&
                      getData.filter(data => data.queueId === queue.id).map((data, index) => ( */}
                        <tr key={index}>
                          <td className='text-left'>
                            {queue?.queueOwner?.name}
                          </td>
                          <td className='text-left'>
                            {queue?.lot?.lotName}
                          </td>
                        </tr>
                      {/* ))} */}
                  </tbody>
                </table>
              </div>
            </AccordionDetails>
          </Accordion>
        ))}

      <Dialog
        open={edit}
        onClose={handleCloseEdit}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title"></DialogTitle>
        <DialogContent>
          <Typography align="center" sx={{ fontWeight: "600", mb: 4 }}>
            Do you want to edit Queue
          </Typography>
          <div className="row">
            <div className="col-4 py-1">
              <b>Queue Name:</b>
            </div>
            <div className="col-8 py-1">
              <TextField
                id="outlined-basic"
                variant="outlined"
                size="small"
                name="queueName"
                value={data.queueName}
                onChange={handleChange}
                error={!!validationErrors.queueName}
                helperText={validationErrors.queueName}
              />
            </div>
          </div>
        </DialogContent>
        <DialogActions className="p-3">
          <Button variant="outlined" onClick={handleCloseEdit}>
            Cancel
          </Button>
          <Button variant="outlined" color="success" onClick={handleEditQueue}>
            Update
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={disable}
        onClose={handleCloseDisable}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title"></DialogTitle>
        <DialogContent>
          <Typography
            id="modal-modal-description"
            sx={{ mt: 2, fontWeight: "600", mb: 2 }}
          >
            <span style={{ color: "red", fontWeight: "400" }}>Note: </span>
            Existing users from the Queue will be Processed as it is.
          </Typography>
          <div className="confirm_but">
            <Button
              variant="outlined"
              onClick={() => toggleIsDisable(indx, editingQueueId)}
            >
              Confirm
            </Button>
          </div>
        </DialogContent>
      </Dialog>
    </div>
  )
}

export default Queues