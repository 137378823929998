import React, { useState, useEffect } from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import CallMadeOutlinedIcon from '@mui/icons-material/CallMadeOutlined';
import PeopleOutlineIcon from '@mui/icons-material/PeopleOutline';
import PermIdentityOutlinedIcon from '@mui/icons-material/PermIdentityOutlined';
import GroupsOutlinedIcon from '@mui/icons-material/GroupsOutlined';
import DownloadIcon from "@mui/icons-material/Download";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { Menu, MenuItem } from '@mui/material';
import { PieChart } from '@mui/x-charts/PieChart';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DateCalendar } from "@mui/x-date-pickers/DateCalendar";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import io from 'socket.io-client';
import { getQR } from '../../../services/api/utils';
import api from '../../../services/api';
import moment from 'moment';

function App() {
  const QrCode = getQR();
  console.log("QrCode======", QrCode);
  const [selectedDateRange, setSelectedDateRange] = useState("Today");
  const [anchorEl, setAnchorEl] = useState(null);
  const [getData, setGetData] = useState([]);
  const [getTableData, setGetTableData] = useState([]);
  console.log("getTableData-------------------", getTableData);
  console.log("getData---------------", getData);

  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleDateRangeChange = (range) => {
    setSelectedDateRange(range);
    setAnchorEl(null);
    getAllData(range);
    getAllTableData(range);
  };

  const getAllData = async (dateRange) => {
    try {
      const dateFilter = getDateFilter(dateRange);
      const response = await api.getCounts(dateFilter);
      if (response) {
        setGetData(response.data);
      } else {
        console.error("Failed to fetch data");
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const getAllTableData = async (dateRange) => {
    try {
      const dateFilter = getDateFilter(dateRange);
      const response = await api.getAllTableDataWithDate(dateFilter);
      console.log("response-----------------------------------", response);
      if (response) {
        setGetTableData(response);
      } else {
        console.error("Failed to fetch data");
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    getAllData(selectedDateRange);
    getAllTableData(selectedDateRange);
  }, [selectedDateRange]);

  const getDateFilter = (range) => {
    const date = new Date();

    if (range === "Today") {
      date.setDate(date.getDate());
    } else if (range === "Yesterday") {
      date.setDate(date.getDate() - 1);
    } else if (range === "Last Week") {
      date.setDate(date.getDate() - 6);
    } else if (range === "Last Month") {
      date.setMonth(date.getMonth() - 1);
    } else if (range === "Last Year") {
      date.setFullYear(date.getFullYear() - 1);
    }

    return date.toISOString();
  };


  const card1 = (
    <React.Fragment>
      <CardContent>
        <div>
          <CallMadeOutlinedIcon />
        </div>
        <Typography variant="h5" component="div">
          {getData?.lotCount}
        </Typography>
        <Typography component="div">
          Lot-Level
        </Typography>
      </CardContent>
    </React.Fragment>
  );

  const card2 = (
    <React.Fragment>
      <CardContent>
        <div>
          <PeopleOutlineIcon />
        </div>
        <Typography variant="h5" component="div">
          {getData?.queueCount}
        </Typography>
        <Typography component="div">
          Queues
        </Typography>
      </CardContent>
    </React.Fragment>
  );

  const card3 = (
    <React.Fragment>
      <CardContent>
        <div>
          <PermIdentityOutlinedIcon />
        </div>
        <Typography variant="h5" component="div">
          {getData?.queueOwnerCount}
        </Typography>
        <Typography component="div">
          Queue Owners
        </Typography>
      </CardContent>
    </React.Fragment>
  );

  const card4 = (
    <React.Fragment>
      <CardContent className='card_content'>
        <div>
          <GroupsOutlinedIcon />
        </div>
        <Typography variant="h5" component="div">
          {getData?.queueMemberCount}
        </Typography>
        <Typography component="div">
          Queue Members
        </Typography>
      </CardContent>
    </React.Fragment>
  );

  function createData(name, calories, fat, carbs, protein) {
    return { name, calories, fat, carbs, protein };
  }

  const rows = [
    createData('Frozen yoghurt', 159, 6.0, 24, 4.0),
    createData('Ice cream sandwich', 237, 9.0, 37, 4.3),
    createData('Eclair', 262, 16.0, 24, 6.0),
    createData('Cupcake', 305, 3.7, 67, 4.3),
    createData('Gingerbread', 356, 16.0, 49, 3.9),
  ];

  const ENDPOINT = process.env.REACT_APP_BASE_URL;

  useEffect(() => {
    const socket = io(ENDPOINT);
    socket.on('msg', (msg) => {
      console.log(msg);
    });

    socket.on('ticket_created', (data) => {
      console.log('Ticket created event received', data);
    });

    return () => {
      socket.disconnect();
    };
  }, []);

  const handleDownload = () => {
    const link = document.createElement("a");
    link.href = QrCode;
    link.download = "qr_code_image.jpg";
    link.click();
  };

  return (
    <>
      <div>
        <div className="flex items-center">
          <div className="text-[17px] font-[600]">Overview</div>
          <div className="mt-2 ml-auto mr-3">
            {QrCode ? (
              <div className="flex items-center flex-col">
                <Button
                  onClick={handleDownload}
                  variant="outlined"
                  endIcon={<DownloadIcon />}
                >
                  Download
                </Button>
                <img
                  width={132}
                  height={132}
                  style={{ width: 132, height: 132 }}
                  src={QrCode}
                  alt="QR Code"
                />
              </div>
            ) : (
              "Qr Code Not Available"
            )}
          </div>
          <div className="border rounded">
            <Button
              id="basic-button-1"
              aria-controls={open ? "basic-menu-1" : undefined}
              aria-haspopup="true"
              aria-expanded={open ? "true" : undefined}
              onClick={handleClick}
            >
              {selectedDateRange}
              <div className="ml-1">
                <KeyboardArrowDownIcon />
              </div>
            </Button>
            <Menu
              id="basic-menu-1"
              anchorEl={anchorEl}
              open={Boolean(anchorEl)}
              onClose={handleClose}
              MenuListProps={{
                "aria-labelledby": "basic-button-1",
              }}
            >
              <MenuItem onClick={() => handleDateRangeChange("Today")}>
                Today
              </MenuItem>
              <MenuItem onClick={() => handleDateRangeChange("Yesterday")}>
                Yesterday
              </MenuItem>
              <MenuItem onClick={() => handleDateRangeChange("Last Week")}>
                Last Week
              </MenuItem>
              <MenuItem onClick={() => handleDateRangeChange("Last Month")}>
                Last Month
              </MenuItem>
              <MenuItem onClick={() => handleDateRangeChange("Last Year")}>
                Last Year
              </MenuItem>
            </Menu>
          </div>
        </div>
        <div className='grid grid-cols-2 md:grid-cols-4 gap-2 mt-10'>
          <div className="">
            <Card variant="outlined" className='card1 rounded h-full'>{card1}</Card>
          </div>
          <div className="">
            <Card variant="outlined" className='card2 rounded h-full'>{card2}</Card>
          </div>
          <div className="">
            <Card variant="outlined" className='card3 rounded h-full'>{card3}</Card>
          </div>
          <div className="">
            <Card variant="outlined" className='card4 rounded h-full'>{card4}</Card>
          </div>
        </div>
        <div className="grid grid-cols-1 md:grid-cols-2 mt-5 gap-3">
          <div className="p-3 rounded" style={{ boxShadow: "0 11px 9px 0 #5989d71c" }}>
            <div className="mb-4 text-[18px] font-[600]">Expected vs Completed</div>
            <div>
              <PieChart
                series={[
                  {
                    data: [
                      { id: 0, value: getData?.sessionCount, label: 'Expected' },
                      { id: 1, value: getData?.ticketCompletedCount, label: 'Completed', color: '#75b775' },
                    ],
                  },
                ]}
                // width={400}
                height={200}
              />
            </div>
          </div>
          <div className="p-3" style={{ boxShadow: "0 11px 9px 0 #5989d71c" }}>
            <div className="mb-4 text-[18px] font-[600]">Joined vs Completed</div>
            <div>
              <PieChart
                series={[
                  {
                    data: [
                      { id: 0, value: getData?.ticketCount, label: 'Joined', color: '#f4c97c' },
                      { id: 1, value: getData?.ticketCompletedCount, label: 'Completed', color: '#75b775' },
                    ],
                  },
                ]}
                // width={400}
                height={200}
              />
            </div>
          </div>
        </div>
        <div className="grid grid-cols-1 mt-5 gap-3">
          <div className='col-span-2 ... p-3 rounded' style={{ boxShadow: "0 11px 9px 0 #5989d71c" }}>
            <TableContainer component={Paper}>
              <Table sx={{ minWidth: 650 }} aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell>Lots-Level</TableCell>
                    <TableCell align="right">Queue</TableCell>
                    <TableCell align="right">Queue Owner</TableCell>
                    <TableCell align="right">Queue Members</TableCell>
                    <TableCell align="right">Status</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {getTableData?.data?.map((data) => (
                    <TableRow
                      key={data.name}
                      sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                    >
                      <TableCell component="th" scope="row">
                        {data?.lot?.lotName}
                      </TableCell>
                      <TableCell align="right">{data?.queue?.queueName}</TableCell>
                      <TableCell align="right">{data?.queueOwner?.name}</TableCell>
                      <TableCell align="right">{data?.queueMember?.name}</TableCell>
                      <TableCell align="right">{data?.status}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </div>
          {/* <div className='p-3 rounded' style={{ boxShadow: "0 11px 9px 0 #5989d71c" }}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DateCalendar />
            </LocalizationProvider>
          </div> */}
        </div>
      </div>
    </>
  );
}

export default App;
