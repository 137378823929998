import React, { useEffect, useState } from 'react';
import AddIcon from "@mui/icons-material/Add";
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControl,
    MenuItem,
    Select,
    TextField,
} from "@mui/material";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import { ToastContainer, toast } from "react-toastify";
import api from "../../../services/api";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import moment from 'moment';
import { getSessionCost, getUserEmail, getUserId, getUserName } from '../../../services/api/utils';

const Sessions = () => {
    const [add, setAdd] = useState(false);
    const [sessionData, setSessionData] = useState({
        sessionCount: "",
        sessionInterval: ""
    });
    const [todaysSessions, setTodaysSessions] = useState([]);
    const [historicalSessions, setHistoricalSessions] = useState([]);
    const [getSessions, setGetSessions] = useState([]);
    const [userName, setUserName] = useState([]);
    const [userEmail, setUserEmail] = useState([]);
    const [sessionCost, setSessionCost] = useState([]);
    const [userId, setUserId] = useState([]);
    const [responseData, setResponseData] = useState([]);
    const [validationErrors, setValidationErrors] = useState({});

    const name = getUserName();
    const email = getUserEmail();
    const cost = getSessionCost();
    const id = getUserId();
    useEffect(() => {
        if (name) {
            setUserName(name);
        }
    }, [name]);

    useEffect(() => {
        if (email) {
            setUserEmail(email);
        }
    }, [email]);

    useEffect(() => {
        if (cost) {
            setSessionCost(cost);
        }
    }, [cost]);

    useEffect(() => {
        if (id) {
            setUserId(id);
        }
    }, [id]);

    const getAllData = async () => {
        try {
            const response = await api.getAllTableData();
            if (response) {
                setGetSessions(response?.data);
            } else {
                console.error("Failed to fetch admins");
            }
        } catch (error) {
            console.error("Error fetching admins:", error);
        }
    };

    const fetchSessionData = async () => {
        try {
            const apiRes = await api.getSessionByUser();
            const res = apiRes?.data
            if (res) {
                setResponseData(res);
            }
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };

    useEffect(() => {
        getAllData();
        fetchSessionData();
    }, []);

    const handleOpenAdd = () => setAdd(true);
    const handleCloseAdd = () => {
        setAdd(false);
        setValidationErrors({});
    };

    const handleChange = (event) => {
        const { name, value } = event.target;
        setSessionData({
            ...sessionData,
            [name]: value
        });
        setValidationErrors({
            ...validationErrors,
            [name]: ''
        });
    };

    const resetForm = () => {
        setSessionData({
            sessionCount: "",
            sessionInterval: ""
        });
        setValidationErrors({});
    };

    const generateTransactionId = () => {
        return Math.random().toString(36).substring(2, 10);
    };

    const randomTransactionId = generateTransactionId();
    console.log(randomTransactionId);
    const sessioncost = parseFloat(sessionCost); // Convert to a number
    const formattedSessionCost = sessioncost.toFixed(2);

    const paymentData = {
        txnid: randomTransactionId,
        amount: formattedSessionCost,
        email: userEmail,
        phone: "9931071882",
        name: userName,
        productinfo: "testing",
        surl: `${process.env.REACT_APP_BASE_URL}/session/get/${userId}/${sessionData?.sessionCount}/${sessionData?.sessionInterval}`,
        furl: process.env.REACT_APP_BASE_URL
    };
    console.log("paymentData---------------", paymentData);

    const handleAddSession = async () => {
        const errors = {};

        if (!sessionData.sessionCount) {
            errors.sessionCount = "Session count is required";
        } else if (sessionData.sessionCount <= 0) {
            errors.sessionCount = "Session count must be a positive number";
        }

        if (!sessionData.sessionInterval) {
            errors.sessionInterval = "Session interval is required";
        } else if (sessionData.sessionInterval <= 0) {
            errors.sessionInterval = "Session interval must be a positive number";
        }
        if (Object.keys(errors).length > 0) {
            setValidationErrors(errors);
            return;
        }
        const apiRes = await api.handlePayment(paymentData);
        if (apiRes.response_code === 200) {
            window.location.href = apiRes?.paymentURL;
            resetForm();
            handleCloseAdd();
            fetchSessionData();
        }
    };

    const formatDate = (dateString) => {
        const options = {
            year: 'numeric',
            month: 'long',
            day: 'numeric',
            hour: '2-digit',
            minute: '2-digit',
            second: '2-digit',
            hour12: false,
        };
        const date = new Date(dateString);
        return new Intl.DateTimeFormat('en-US', options).format(date);
    };
    const createdAt = getSessions[0]?.session?.createdAt;
    const formattedDate = createdAt ? formatDate(createdAt) : 'Date not available';

    useEffect(() => {
        const today = moment().format("YYYY-MM-DD"); // Get today's date using moment
        const todaysSessions = responseData.filter(
            (session) => moment(session?.createdAt).format("YYYY-MM-DD") === today
        );
        setTodaysSessions(todaysSessions);

        const historicalSessions = responseData.filter(
            (session) => moment(session?.createdAt).format("YYYY-MM-DD") !== today
        );
        setHistoricalSessions(historicalSessions);
    }, [responseData]);

    return (
        <div>
            <div>
                <div className="my-6 ml-auto text-black bg-white rounded-[35px] w-fit flex items-center border border-[#ababab] py-[8px] px-[10px] cursor-pointer"
                    onClick={handleOpenAdd}>
                    <div>
                        <AddIcon className='text-white bg-[#528dfe] rounded-full mr-4 text-[28px]' />
                    </div>
                    <div className='font-[500]'>
                        Add new Session
                    </div>
                </div>
                <Dialog
                    open={add}
                    onClose={handleCloseAdd}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title"></DialogTitle>
                    <DialogContent>
                        <Typography align="center" sx={{ fontWeight: "600", mb: 4 }}>
                            Do you want to add new session
                        </Typography>
                        <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                            <div>
                                <b>Expected Queue member sessionCount:</b>
                            </div>
                            <div>
                                <TextField
                                    id="sessionCount"
                                    name="sessionCount"
                                    variant="outlined"
                                    size="small"
                                    type="number"
                                    value={sessionData.sessionCount}
                                    onChange={handleChange}
                                    error={!!validationErrors.sessionCount}
                                    helperText={validationErrors.sessionCount}
                                />
                            </div>
                            <div>
                                <b>Session Interval required to process per queue member (per minutes):</b>
                            </div>
                            <div>
                                <TextField
                                    id="sessionInterval"
                                    name="sessionInterval"
                                    variant="outlined"
                                    size="small"
                                    type="number"
                                    value={sessionData.sessionInterval}
                                    onChange={handleChange}
                                    error={!!validationErrors.sessionInterval}
                                    helperText={validationErrors.sessionInterval}
                                />
                            </div>
                        </div>
                    </DialogContent>
                    <DialogActions className="p-3">
                        <Button className='capitalize' variant="outlined" onClick={handleCloseAdd}>
                            Cancel
                        </Button>
                        <Button
                            variant="outlined"
                            color="success"
                            onClick={handleAddSession}
                        >
                            Add
                        </Button>
                    </DialogActions>
                </Dialog>
            </div>
            <div>
                <h1 className="my-3 font-semibold p-3">Todays Session</h1>
            </div>
            {todaysSessions &&
                todaysSessions.length > 0 &&
                todaysSessions.map((session, index) => (
                    <Accordion className="mb-2 accordian_main">
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1a-content"
                            id="panel1a-header"
                        >
                            <div>Session</div>
                            <div className="ml-auto mr-3" variant="body1">
                                {formatDate(session?.createdAt)}
                            </div>
                        </AccordionSummary>
                        <AccordionDetails className="p-0">
                            <div className="w-full rounded datatable">
                                <table className='w-full'>
                                    <thead>
                                        <tr>
                                            <th className="p-3">Lots-Level</th>
                                            <th className="p-3">Queue</th>
                                            <th className="p-3">Queue Owner</th>
                                            <th className="p-3">Queue Members</th>
                                            <th className="p-3">Status</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {getSessions &&
                                            getSessions.length > 0 &&
                                            getSessions.filter(data => data.sessionId === session.id).map((data, index) => (
                                                <tr>
                                                    <td>
                                                        <div>{data?.lot?.lotName}</div>
                                                    </td>
                                                    <td>
                                                        <div>{data?.queue?.queueName}</div>
                                                    </td>
                                                    <td>
                                                        {data?.queueOwner?.name}
                                                    </td>{" "}
                                                    <td>
                                                        {data?.queueMember?.name}
                                                    </td>
                                                    <td className="capitalize">
                                                        {data?.status &&
                                                            data?.status.replaceAll("_", " ")}
                                                    </td>
                                                </tr>
                                            ))}
                                    </tbody>
                                </table>
                            </div>
                        </AccordionDetails>
                    </Accordion>
                ))}
            <div>
                <h1 className="my-3 font-semibold p-3">Session History</h1>
            </div>
            {historicalSessions &&
                historicalSessions.length > 0 &&
                historicalSessions.map((session, index) => (
                    <Accordion className="mb-2 accordian_main">
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1a-content"
                            id="panel1a-header"
                        >
                            <div>Session</div>
                            <div className="ml-auto mr-3" variant="body1">
                                {formatDate(session?.createdAt)}
                            </div>
                        </AccordionSummary>
                        <AccordionDetails className="p-0">
                            <div className="w-full rounded datatable">
                                <table className='w-full'>
                                    <thead>
                                        <tr>
                                            <th className="p-3">Lots-Level</th>
                                            <th className="p-3">Queue</th>
                                            <th className="p-3">Queue Owner</th>
                                            <th className="p-3">Queue Members</th>
                                            <th className="p-3">Status</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {getSessions &&
                                            getSessions.length > 0 &&
                                            getSessions.filter(data => data.sessionId === session.id).map((data, index) => (
                                                <tr>
                                                    <td>
                                                        <div>{data?.lot?.lotName}</div>
                                                    </td>
                                                    <td>
                                                        <div>{data?.queue?.queueName}</div>
                                                    </td>
                                                    <td>
                                                        {data?.queueOwner?.name}
                                                    </td>{" "}
                                                    <td>
                                                        {data?.queueMember?.name}
                                                    </td>
                                                    <td className="capitalize">
                                                        {data?.status &&
                                                            data?.status.replaceAll("_", " ")}
                                                    </td>
                                                </tr>
                                            ))}
                                    </tbody>
                                </table>
                            </div>
                        </AccordionDetails>
                    </Accordion>
                ))}
        </div>
    )
}

export default Sessions;
