import React, { useEffect, useState } from 'react';
import api from '../../../services/api';

function PaymentReport() {
  const [payments, setPayments] = useState([]);
  console.log("payments-----------",payments);

  const getPaymentData = async () => {
    try {
      const apiRes = await api.getAllPayment();
      const res = apiRes?.data
      if (res) {
        setPayments(res);
      }
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  }

  useEffect(() => {
    getPaymentData();
  }, []);

  return (
    <>
      <div className="">
        <h1 className="text-2xl font-bold mb-4">Payment Report</h1>
        <div className="overflow-x-auto">
          <table className="min-w-full border rounded-lg">
            <thead>
              <tr className="bg-gray-200 text-gray-600 uppercase text-sm leading-normal">
                <th className="py-3 px-6 text-left">Admin Name</th>
                <th className="py-3 px-6 text-left">Admin Email</th>
                <th className="py-3 px-6 text-left">Name on Card</th>
                <th className="py-3 px-6 text-left">Bank Reference Number</th>
                <th className="py-3 px-6 text-left">Amount</th>
                <th className="py-3 px-6 text-left">Transaction ID</th>
                <th className="py-3 px-6 text-left">Date</th>
              </tr>
            </thead>
            <tbody className="text-gray-600 text-sm font-light">
              {payments.map((payment) => (
                <tr key={payment.id} className="border-b font-semibold border-gray-200 hover:bg-gray-100">
                  <td className="py-3 px-6 text-left whitespace-nowrap">{payment?.user?.name}</td>
                  <td className="py-3 px-6 text-left whitespace-nowrap">{payment?.user?.email}</td>
                  <td className="py-3 px-6 text-left">{payment.name_on_card}</td>
                  <td className="py-3 px-6 text-left">{payment.bank_ref_num}</td>
                  <td className="py-3 px-6 text-left">{payment.amount}</td>
                  <td className="py-3 px-6 text-left">{payment.txnid}</td>
                  <td className="py-3 px-6 text-left">{new Date(payment.createdAt).toLocaleDateString()}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
}

export default PaymentReport;
