import { handleResponse, API, getUserId, getToken } from "./utils";

const auth = {
  login: async (data) => {
    let response = null;
    console.log("response", response);
    try {
      response = await API.post("/user/login", data);
    } catch (e) {
      response = e;
    }
    console.log("response", response);
    return handleResponse(response);
  },
  signUp: async (data) => {
    let response = null;
    try {
      response = await API.post("/user/register", data);
    } catch (e) {
      response = e;
    }
    return handleResponse(response);
  },
  signUpForOwner: async (data) => {
    let response = null;
    const userId = getUserId();
    try {
      response = await API.post(`/user/registerOwner/${userId}`, data);
    } catch (e) {
      response = e;
    }
    return handleResponse(response);
  },
  signUpForMember: async (data) => {
    let response = null;
    const userId = getUserId();
    try {
      response = await API.post(`/user/registerMember/${userId}`, data);
    } catch (e) {
      response = e;
    }
    return handleResponse(response);
  },
  getAllAdmins: async () => {
    let response = null;
    try {
      response = await API.get("/user/get/all");
    } catch (e) {
      response = e;
    }
    return handleResponse(response);
  },
  getUserById: async (id) => {
    try {
      let response = null;
      await API.get(`/user/get/${id}`)
        .then(function (res) {
          response = res;
        })
        .catch(function (error) {
          response = error;
        });
      return handleResponse(response);
    } catch (err) {
      console.log("Error from server " + err);
    }
  },
  updateProfile: async (data) => {
    const token = await getToken();
    const id = getUserId();
    let response = null;
    try {
      response = await API.put(`/user/update/${id}`, data, {
        params: {},
        headers: { Authorization: "Bearer: " + token },
      });
    } catch (e) {
      response = e;
    }
    return handleResponse(response);
  },
  sendAdminResetPass: async (data) => {
    let response = null;
    try {
      response = await API.post("/user/send/reset/password", data);
    } catch (e) {
      response = e;
    }
    return handleResponse(response);
  },
  handleAdminResetPassword: async (data) => {
    let response = null;
    try {
      response = await API.put(`/user/reset/password`, data);
    } catch (e) {
      response = e;
    }
    return handleResponse(response);
  },
};

export default auth;
