import React, { useEffect, useState } from 'react';
import AddIcon from '@mui/icons-material/Add';
import Paper from '@mui/material/Paper';
import TableContainer from '@mui/material/TableContainer';
import Table from '@mui/material/Table';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import TableBody from '@mui/material/TableBody';
import { Button, IconButton, InputAdornment, styled } from '@mui/material';
import AdminModal from '../../../../src/components/superadmin/AdminModal';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import api from "../../../services/api";
import { toast } from 'react-toastify';
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import TextField from "@mui/material/TextField";
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';

export default function SuperAdminDashboard() {

  const [adminData, setAdminData] = useState();
  console.log("adminData", adminData);
  const [loading, setLoading] = useState(false);

  const Content = styled('div')({
    // marginLeft: '240px', // Width of the sidebar
    padding: '16px', // Adjust as needed
  });

  const modalStyle = {
    width: "600px", // Set your desired width
  };

  const [isModalOpen, setModalOpen] = useState(false);
  const [editModalOpen, setEditModalOpen] = useState(false);
  const [editingAdminId, setEditingAdminId] = useState(null);
  const [showPassword, setShowPassword] = useState(false);
  const [formData, setFormData] = useState({
    name: "",
    mobile: "",
    email: "",
    password: "",
    sessionCost: "",
    role: "",
    isVerified: true
  });
  console.log("formData", formData);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prevState => ({
      ...prevState,
      [name]: value
    }));
  };

  const resetForm = () => {
    setFormData({
      ...formData,
      name: "",
      mobile: "",
      email: "",
      password: "",
      sessionCost: ""
    });
  };

  const handleCreateAdmin = async () => {
    if (!formData.sessionCost) {
      toast.error("Session Cost should not be blank");
      return;
    }
    if (isNaN(formData.sessionCost)) {
      toast.error("Session Cost must be a number");
      return;
    }
    try {
      formData.role = 'admin';
      const res = await api.signUp(formData);
      console.log("🚀 ~ handleLogin ~ res:", res);
      if (res.response_code === 200) {
        toast.success("Registration Successfully ");
        getAllAdmins();
        localStorage.setItem("user", JSON.stringify(res));
        closeModal();
        resetForm();
      }
      // setIsLoading(false);
    } catch (error) {
      toast.error(error?.message);
      console.log("🚀 ~ handleLogin ~ error:", error);
    }
  };

  const handleEditAdmin = async () => {
    if (!formData.name || !formData.email || !formData.mobile) {
      toast.error("All fields are required");
      return;
    }
    if (editingAdminId) {
      try {
        const update = await api.updateAdmin(formData, editingAdminId);
        if (update.response_code === 200) {
          toast.success("Updated Successfully");
          handleCloseEdit();
          getAllAdmins();
        }
      } catch (error) {
        toast.error("Failed to Update queueowner");
      }
    }
  };

  const handleOpenEdit = async (adminId) => {
    // Find the lot to edit based on the provided adminId
    try {
      const response = await api.getAdmin(adminId);
      console.log("response----------123=========", response);
      if (response) {
        setEditingAdminId(adminId); // Store the ID of the lot being edited
        setFormData({
          name: response?.data?.name,
          // password: '',
          email: response?.data?.email,
          mobile: response?.data?.mobile,
          sessionCost: response?.data?.sessionCost,
        });
        setEditModalOpen(true);
      } else {
        console.error("Failed to fetch data");
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const handleCloseEdit = () => {
    setEditModalOpen(false);
    setEditingAdminId(null);
    resetForm();
  };

  const openModal = () => {
    setModalOpen(true);
  };

  const closeModal = () => {
    setModalOpen(false);
  };

  const getAllAdmins = async () => {
    try {
      const response = await api.getAllAdmins();
      if (response) {
        setAdminData(response?.data);
      } else {
        console.error("Failed to fetch admins");
      }
    } catch (error) {
      console.error("Error fetching admins:", error);
    }
  };

  useEffect(() => {
    getAllAdmins();
  }, []);

  function handleClose() {
    setLoading(false)
  }

  async function handleDisableUser(adminId, isDisabled) {
    try {
      const update = await api.updateAdmin({ is_disable: !isDisabled }, adminId);
      if (update.response_code === 200) {
        const status = !isDisabled ? "disabled" : "enabled";
        toast.success(`Admin ${status} successfully`);
        getAllAdmins();
      }
    } catch (error) {
      toast.error("Failed to Update Admin");
    }
  }

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  return (
    <div >
      {/* <AdminModal open={isModalOpen} onClose={closeModal} onOpen={edit} Close={handleCloseEdit} getAllAdmins={getAllAdmins} editingAdminId={editingAdminId} /> */}
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 99999 }}
        open={loading}
        onClick={handleClose}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <Content>
        <div className="p-6 w-full">
          <div className="mb-6 sticky_button">
            <button onClick={openModal} className="bg-blue-500 hover:bg-blue-600 text-white font-semibold py-2 px-4 rounded-full flex items-center">
              <AddIcon className="mr-2" />
              Create Admin
            </button>
          </div>

          <Paper className="w-full shadow-md">
            <TableContainer>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell className="font-semibold">Name</TableCell>
                    <TableCell className="font-semibold">Email</TableCell>
                    <TableCell className="font-semibold">Mobile No.</TableCell>
                    <TableCell className="font-semibold">Role</TableCell>
                    <TableCell className="font-semibold">Email Verified</TableCell>
                    <TableCell className="font-semibold">QR Code</TableCell>
                    <TableCell className="font-semibold">Disable User</TableCell>
                    <TableCell className="font-semibold">Edit User</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {adminData && adminData?.map((admin, index) => (
                    <TableRow key={index}>
                      <TableCell>{admin.name}</TableCell>
                      <TableCell>{admin.email}</TableCell>
                      <TableCell>{admin.mobile}</TableCell>
                      <TableCell>{admin.role}</TableCell>
                      <TableCell>{admin.isVerified ? 'Verified' : 'Not Verified'}</TableCell>
                      <TableCell>
                        {admin?.qrCode ? <img width={132} height={132} src={admin.qrCode} alt="QR Code" /> : 'QR code not available'}
                      </TableCell>
                      <TableCell>
                        <Button variant='outlined' onClick={() => handleDisableUser(admin.id, admin.is_disable)} color={!admin.is_disable ? 'error' : 'primary'}  >{!admin.is_disable ? 'Disable User' : 'Enable User'} </Button>
                      </TableCell>
                      <TableCell>
                        <Button variant='outlined' onClick={() => handleOpenEdit(admin?.id)}> Edit </Button>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Paper>
        </div>
      </Content>

      <Dialog PaperProps={{ style: modalStyle }} open={isModalOpen} onClose={closeModal}>
        <DialogTitle>Create New Admin</DialogTitle>
        <DialogContent>
          <form>
            <TextField
              fullWidth
              label="Full Name"
              name="name"
              value={formData?.name}
              onChange={handleChange}
              margin="normal"
            />
            <TextField
              fullWidth
              label="Email"
              name="email"
              value={formData?.email}
              onChange={handleChange}
              autoComplete="off"
              margin="normal"
            />
            <TextField
              fullWidth
              label="Mobile"
              name="mobile"
              value={formData?.mobile}
              onChange={handleChange}
              margin="normal"
            />
            <TextField
              fullWidth
              label="Session Cost"
              name="sessionCost"
              type="number"
              value={formData?.sessionCost}
              onChange={handleChange}
              margin="normal"
            />
            <TextField
              fullWidth
              label="Password"
              name="password"
              type={showPassword ? "text" : "password"}
              value={formData?.password}
              autoComplete="off"
              onChange={handleChange}
              margin="normal"
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton onClick={togglePasswordVisibility}>
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
            <Button onClick={handleCreateAdmin} color="primary" variant="outlined">
              Create
            </Button>
          </form>
        </DialogContent>
      </Dialog>

      <Dialog PaperProps={{ style: modalStyle }} open={editModalOpen} onClose={handleCloseEdit}>
        <DialogTitle>Edit Admin</DialogTitle>
        <DialogContent>
          <form>
            <TextField
              fullWidth
              label="Full Name"
              name="name"
              value={formData?.name}
              onChange={handleChange}
              margin="normal"
            />
            <TextField
              fullWidth
              label="Email"
              name="email"
              disabled
              value={formData?.email}
              onChange={handleChange}
              margin="normal"
            />
            <TextField
              fullWidth
              label="Mobile"
              name="mobile"
              value={formData?.mobile}
              onChange={handleChange}
              margin="normal"
            />
            <TextField
              fullWidth
              label="Session Cost"
              name="sessionCost"
              type="number"
              value={formData?.sessionCost}
              onChange={handleChange}
              margin="normal"
            />
            <TextField
              fullWidth
              label="Password"
              name="password"
              type={showPassword ? "text" : "password"}
              // value={formData?.password}
              onChange={handleChange}
              margin="normal"
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton onClick={togglePasswordVisibility}>
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
            <Button onClick={handleEditAdmin} color="primary" variant="outlined">
              Update
            </Button>
          </form>
        </DialogContent>
      </Dialog>
    </div>
  );
}
