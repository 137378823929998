import React, { useEffect, useState } from 'react';
import AddIcon from "@mui/icons-material/Add";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import api from '../../../services/api';
import { toast } from 'react-toastify';
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

const Rebalance = () => {
  const [add, setAdd] = useState(false);
  const [responseData, setResponseData] = useState([]);

  const handleOpenAdd = () => setAdd(true);
  const handleCloseAdd = () => setAdd(false);

  const getRebalancedQueueData = async () => {
    try {
      const apiRes = await api.getRebalanced();
      const res = apiRes?.data;
      if (apiRes?.response_code === 200) {
        setResponseData(res);
      }
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  useEffect(() => {
    getRebalancedQueueData();
  }, []);

  const handleAssignMember = async () => {
    try {
      const apiRes = await api.assignQueueMembers();
      const res = apiRes?.data;
      if (apiRes?.response_code === 200) {
        toast.success("Rebalance successful");
        getRebalancedQueueData();
      }
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  const groupByLotName = (data) => {
    return data.reduce((acc, item) => {
      const lotName = item.queue.lot.lotName;
      if (!acc[lotName]) {
        acc[lotName] = [];
      }
      acc[lotName].push(item);
      return acc;
    }, {});
  };

  const groupedData = groupByLotName(responseData);

  return (
    <>
      <div className="my-6 ml-auto text-white rounded-[35px] w-fit flex items-center bg-[#528dfe] py-[6px] px-[12px] cursor-pointer">
        <div className='font-[500]' onClick={handleAssignMember}>
          Re-Balance Queue
        </div>
      </div>
      {Object.keys(groupedData).map((lotName, index) => (
        <Accordion className="mb-2 accordian_main" key={index}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls={`panel${index}-content`}
            id={`panel${index}-header`}
          >
            <Typography style={{ maxWidth: "250px", wordWrap: "break-word" }}>
              {lotName}
            </Typography>
            <div className="action_menu border-0 ms-auto"></div>
          </AccordionSummary>
          <AccordionDetails className="details">
            <div className="customTable">
              <table>
                <thead>
                  <tr>
                    <th className="p-3">Queue</th>
                    <th className="p-3">Queue Member</th>
                  </tr>
                </thead>
                <tbody>
                  {groupedData[lotName].map((item, idx) => (
                    <tr key={idx}>
                      <td className="p-3">{item.queue.queueName}</td>
                      <td className="p-3">{item.queueMember.name || 'No members'}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </AccordionDetails>
        </Accordion>
      ))}
    </>
  );
};

export default Rebalance;
