import React, { useState, useEffect } from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import CallMadeOutlinedIcon from '@mui/icons-material/CallMadeOutlined';
import PeopleOutlineIcon from '@mui/icons-material/PeopleOutline';
import PermIdentityOutlinedIcon from '@mui/icons-material/PermIdentityOutlined';
import GroupsOutlinedIcon from '@mui/icons-material/GroupsOutlined';
import api from '../../../services/api';

const Reports = () => {
    const [getData, setGetData] = useState([]);

    useEffect(() => {
        const getAllData = async () => {
            const date = new Date();
            try {
                const response = await api.getDataForSuperAdmin(date);
                if (response) {
                    setGetData(response.data);
                } else {
                    console.error("Failed to fetch data");
                }
            } catch (error) {
                console.error("Error fetching data:", error);
            }
        };
        getAllData()
    }, []);

    const card1 = (
        <React.Fragment>
            <CardContent>
                <div>
                    <CallMadeOutlinedIcon />
                </div>
                <Typography variant="h5" component="div">
                    {getData?.sessionCount}
                </Typography>
                <Typography component="div">
                    Total Sessions
                </Typography>
            </CardContent>
        </React.Fragment>
    );

    const card2 = (
        <React.Fragment>
            <CardContent>
                <div>
                    <PeopleOutlineIcon />
                </div>
                <Typography variant="h5" component="div">
                    {getData?.queueMemberCount}
                </Typography>
                <Typography component="div">
                    Total Queue Members
                </Typography>
            </CardContent>
        </React.Fragment>
    );

    const card3 = (
        <React.Fragment>
            <CardContent>
                <div>
                    <PermIdentityOutlinedIcon />
                </div>
                <Typography variant="h5" component="div">
                    {getData?.adminCount}
                </Typography>
                <Typography component="div">
                    Total Admins
                </Typography>
            </CardContent>
        </React.Fragment>
    );

    const card4 = (
        <React.Fragment>
            <CardContent className='card_content'>
                <div>
                    <GroupsOutlinedIcon />
                </div>
                <Typography variant="h5" component="div">
                    {getData?.queueOwnerCount}
                </Typography>
                <Typography component="div">
                    Total Queue Owners
                </Typography>
            </CardContent>
        </React.Fragment>
    );
    return (
        <>
            <div className='grid grid-cols-2 md:grid-cols-4 gap-2 mt-10'>
                <div className="">
                    <Card variant="outlined" className='card1 rounded h-full'>{card1}</Card>
                </div>
                <div className="">
                    <Card variant="outlined" className='card2 rounded h-full'>{card2}</Card>
                </div>
                <div className="">
                    <Card variant="outlined" className='card3 rounded h-full'>{card3}</Card>
                </div>
                <div className="">
                    <Card variant="outlined" className='card4 rounded h-full'>{card4}</Card>
                </div>
            </div>
        </>
    )
}

export default Reports