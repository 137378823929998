import React, { useEffect, useState } from 'react'
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { Button } from '@mui/material';
import api from '../../../services/api';
import { Link } from 'react-router-dom';
import { getUserId } from '../../../services/api/utils';

const Sessions = () => {
  const [userData, setUserData] = useState(null);
  console.log("userData", userData);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const id = await getUserId();
        const apiRes = await api.getUserById(id);
        const res = apiRes?.data
        if (res) {
          setUserData(res);
        }
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };
    fetchData();
  }, []);

  return (
    <div>
      <h1 className='mb-5 text-[18px] font-[600]'>Users</h1>
      <div className='grid'>
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>Full Name</TableCell>
                <TableCell>Mobile</TableCell>
                <TableCell>Email</TableCell>
                <TableCell>Action</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {userData && (
                <TableRow>
                  <TableCell>{userData.name}</TableCell>
                  <TableCell>{userData.mobile}</TableCell>
                  <TableCell>{userData.email}</TableCell>
                  <TableCell>
                    <Link to={`/admin/profileEdit/${userData?.id}`}>
                      <Button>Edit</Button>
                    </Link>
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    </div>
  )
}

export default Sessions;
