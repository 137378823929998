
export const logOut = async () => {
  localStorage.removeItem("user");
  localStorage.removeItem("loginData");
  localStorage.removeItem("role");
};
export const getRole = async () => {
  if (typeof window !== "undefined") {
    // Check if running in a browser environment
    return localStorage.getItem("role");
  } else {
    // Handle the case when running in a non-browser environment
    return null; // Or throw an error, or handle it in another appropriate way
  }
};

export const ValidateEmail = (email) => {
  let re = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
  return re.test(email);
};

export const ValidateMobile = (mobile) => {
  let re = /^\d{10,13}$/;
  return re.test(mobile);
};

export const ValidateAlphanumeric = (text) => {
  let re = /^[a-zA-Z0-9\s]+$/;
  return re.test(text);
};

export const ValidateLength = (text, max = 25, min = 1) => {
  return text.length >= min && text.length <= max;
};

export const PasswordStrength = (password) => {
  let re = /^(?=.*[a-z])(?=.*[A-Z])(?=.{8,24})(?=.*[0-9])(?=.*[@$!%*#?&])/;
  return re.test(password);
};

export const ValidateAlphabetsAndSpaces = (text) => {
  let re = /^[a-zA-Z\s]+$/;
  return re.test(text);
};

// export function objectIdToDecimal(id) {
//   const decimalValue = parseInt(id, 16);

//   return decimalValue;
// }
// export const parseId = (id) => {
//   if (!id) return '';
//   return parseInt(`${id.substring(0, 4)}${id.substring(20)}`, 16).toString();
// };

// const spacesEndpoint = new S3({
//   endpoint: "blr1.r2.cloudflarestorage.com",
//   region: "auto",
//   credentials: {
//     accessKeyId: "60dcfc0a4b69ed53ac1fc009b629c96d",
//     secretAccessKey: "831b304701903305eb3e5f7a59dc533f5ec38a3202072677e1c22d837668613d",
//   },
// });

// export async function uploadToS3(filePath: any, fileName: any) {
//   try {

//     const compressedFile = await imageCompression(filePath, {
//       maxSizeMB: 1.5,
//       maxWidthOrHeight: 1920,
//     });

//     const uploadParams = {
//       Bucket: "krattos",
//       Key: fileName,
//       Body: compressedFile,
//       ACL: "public-read",
//     };

//     const uploadResponse = await spacesEndpoint.upload(uploadParams).promise();
//     console.log("File uploaded to S3:", uploadResponse.Location);
//     return uploadResponse.Location;
//   } catch (err) {
//     console.log("Error uploading to S3:", err);
//   }
// }
