import React, { useEffect, useState } from 'react'
import AddIcon from "@mui/icons-material/Add";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  MenuItem,
  Select,
  TextField,
  Tooltip,
} from "@mui/material";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import { MaterialReactTable } from "material-react-table";
import api from '../../../services/api';
import { toast } from 'react-toastify';
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { IconButton, InputAdornment } from '@mui/material';

const QueueMembers = () => {
  const [data, setData] = useState({
    name: "",
    mobile: "",
    email: "",
    password: "",
    role: "",
    isVerified: true,
    lotId: null,
    queueId: null,
  });
  const [getData, setGetData] = useState([]);
  const [getTicketData, setGetTicketData] = useState([]);
  console.log("getTicketData----------==================", getTicketData);
  const [queueMemberData, setQueueMemberData] = useState(data);
  const [lotLevel, setLotLevel] = useState("Select lot-level");
  const [lotLevels, setlotLevels] = React.useState(null);
  const [queueSelect, setQueueSelect] = useState("Select queue");
  const [responseData, setResponseData] = useState([]);
  const [getQueueData, setGetQueueData] = useState([]);
  const [selectedLotId, setSelectedLotId] = useState(null);
  const [getQueueMemberData, setGetQueueMemberData] = useState([]);
  console.log("getQueueMemberData--------===================", getQueueMemberData);
  const [add, setAdd] = useState(false);
  const [edit, setEdit] = useState(false);
  const [editingQueuememberId, setEditingQueueMemberId] = useState(null);
  const [showPassword, setShowPassword] = useState(false);

  const getAllData = async () => {
    try {
      const response = await api.getAllTableData();
      if (response) {
        setGetData(response?.data);
      } else {
        console.error("Failed to fetch admins");
      }
    } catch (error) {
      console.error("Error fetching admins:", error);
    }
  };

  const ticketData = async () => {
    try {
      const response = await api.getTicketData();
      if (response) {
        setGetTicketData(response?.ticketData);
      } else {
        console.error("Failed to fetch admins");
      }
    } catch (error) {
      console.error("Error fetching admins:", error);
    }
  };

  const fetchQueueMembersData = async () => {
    try {
      const apiRes = await api.getQueueMembers();
      const res = apiRes?.data
      if (res) {
        setGetQueueMemberData(res);
      }
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  useEffect(() => {
    getAllData();
    fetchQueueMembersData();
    ticketData();
  }, []);

  const columns = [
    // { header: "ID", id: "id", accessorKey: "id" },
    {
      header: "	Token No.",
      id: "ticketNumber",
      accessorKey: "ticketNumber",
    },
    {
      header: "Name",
      id: "name",
      accessorKey: "queueMember.name",
    },
    {
      header: "Queue",
      id: "queueName",
      accessorKey: "queue.queueName",
    },
    {
      header: "Lots-Level",
      id: "lotName",
      accessorKey: "lot.lotName",
    },
    {
      header: "Action",
      id: "action",
      accessorKey: "action",
      Cell: ({ row }) => {
        return (
          <div className="gap-2 flex ">
            <Button
              variant="outlined"
              size="small"
              color="primary"
            >
              Change
            </Button>
          </div>
        );
      },
    },
  ];

  const handleOpenAdd = () => setAdd(true);
  const handleCloseAdd = () => setAdd(false);

  const handleChange = (event) => {
    const { name, value } = event.target;
    setData({
      ...data,
      [name]: value
    });
  };

  const handleLotLevelChange = (event) => {
    setLotLevel(event.target.value);
    setData({
      ...data,
      lotId: event.target.value,
    });
  };

  const handlelotChange = (event) => {
    setlotLevels(event.target.value);
    setQueueMemberData({
      ...queueMemberData,
      lotId: event.target.value,
    });
  };

  const handleQueueSelectChange = (event) => {
    setQueueSelect(event.target.value);
    setData({
      ...data,
      queueId: event.target.value,
    });
  };

  const resetForm = () => {
    setData({
      ...data,
      name: "",
      mobile: "",
      email: "",
      password: "",
    });
    setLotLevel(null);
    setQueueSelect(null);
  };

  const handleAddQueueMember = async () => {
    if (!data.lotId) {
      toast.error("Please select a lot-level");
      return;
    }
    if (!data.queueId) {
      toast.error("Please select a queue");
      return;
    }
    try {
      data.role = 'queue_member';
      const queue = getQueueData.find(q => q.id === data.queueId);
      if (!queue) {
        toast.error("Queue not found");
        return;
      }
      console.log("queue-------=============", queue);
      const queueownerId = queue.queueownerId;
      console.log("queueownerId----------======================", queueownerId);
      if (queueownerId === null) {
        toast.error("First create Queue owner");
        return;
      }
      const res = await api.signUpForMember({ ...data, queueownerId });
      if (res.response_code === 200) {
        toast.success("Registration Successfully ");
        localStorage.setItem("user", JSON.stringify(res));
        handleCloseAdd();
        resetForm();
        fetchQueueMembersData();
        ticketData();
        getAllData();
      }
    } catch (error) {
      toast.error(error?.message);
    }
  };

  useEffect(() => {
    const fetchLotData = async () => {
      try {
        const apiRes = await api.getLotByUser();
        const res = apiRes?.data
        if (res) {
          setResponseData(res);
          setlotLevels(res[0]?.id || null);
        }
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };
    fetchLotData();
  }, []);

  useEffect(() => {
    const fetchQueueData = async () => {
      try {
        const apiRes = await api.getQueueByUser();
        const res = apiRes?.data
        if (res) {
          setGetQueueData(res);
        }
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };
    fetchQueueData();
  }, []);

  const handleOpenEdit = async (queueMemberId) => {
    console.log("getTicketData--------------================", getTicketData);
    const memberToEdit = getTicketData.find((ticket) => ticket.queuememberId === queueMemberId);
    console.log("memberToEdit--------======--------", memberToEdit);
    try {
      const response = await api.getQueueMember(queueMemberId);
      console.log("response----------123=========", response);
      if (response) {
        console.log("queue data:", response?.data);
        setEditingQueueMemberId(queueMemberId);
        setData({
          name: response?.data?.name,
          // password: '',
          email: response?.data?.email,
          mobile: response?.data?.mobile,
        });
        // setLotLevel(response.data.lotId);
        // setQueueSelect(response.data.queueId);
        setLotLevel(memberToEdit?.lotId);
        setQueueSelect(memberToEdit?.queueId);
        setEdit(true);
      } else {
        console.error("Failed to fetch data");
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const handleCloseEdit = () => {
    setEdit(false);
    setEditingQueueMemberId(null);
    resetForm();
  };

  const handleEditQueueMember = async () => {
    if (!data.name || !data.email || !data.mobile) {
      toast.error("All fields are required");
      return;
    }
    if (!data.name.trim()) {
      toast.error("Queue Member Name should not be empty or only spaces");
      return;
    }
    if (editingQueuememberId) {
      try {
        const update = await api.updateQueueMember(data, editingQueuememberId);
        if (update.response_code === 200) {
          toast.success("Updated Successfully");
          handleCloseEdit();
          fetchQueueMembersData();
          getAllData();
          ticketData();
        }
      } catch (error) {
        toast.error("Failed to Update queueowner");
      }
    }
  };

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  return (
    <div>
      <div className='flex items-center'>
        <div className="action_menu rounded border border-[#ababab]">
          {/* <Box sx={{ minWidth: 120 }}>
            <FormControl fullWidth>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                style={{ maxWidth: "250px", wordWrap: "break-word" }}
                value={lotLevels}
                onChange={handlelotChange}
              >
                <MenuItem value="Select lot-level" disabled>
                  Select lot-level
                </MenuItem>
                {responseData.map((lot) => (
                  <MenuItem
                    style={{ maxWidth: "250px", wordWrap: "break-word" }}
                    className="menu"
                    disabled={lot.is_disable}
                    key={lot.id}
                    value={lot.id}
                  >
                    {lot.lotName}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Box> */}
        </div>

        <div className='my-6 ml-auto'>
          <div className="text-black bg-white rounded-[35px] w-fit flex items-center border border-[#ababab] py-[8px] px-[10px] cursor-pointer"
            onClick={handleOpenAdd}>
            <div>
              <AddIcon className='text-white bg-[#528dfe] rounded-full mr-4 text-[28px]' />
            </div>
            <div className='font-[500]'>
              Add new Queue Member
            </div>
          </div>
          <Dialog
            open={add}
            onClose={handleCloseAdd}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title"></DialogTitle>
            <DialogContent>
              <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                <div>
                  <b>Member Name:</b>
                </div>
                <div>
                  <TextField
                    id="outlined-basic"
                    variant="outlined"
                    size="small"
                    type="text"
                    name='name'
                    value={data?.name}
                    onChange={handleChange}
                  />
                </div>
                <div>
                  <b>Email:</b>
                </div>
                <div>
                  <TextField
                    id="outlined-basic"
                    variant="outlined"
                    size="small"
                    type="email"
                    name='email'
                    value={data?.email}
                    onChange={handleChange}
                  />
                </div>
                <div>
                  <b>Password:</b>
                </div>
                <div>
                  <TextField
                    id="outlined-basic"
                    variant="outlined"
                    size="small"
                    type={showPassword ? 'text' : 'password'}
                    name='password'
                    value={data?.password}
                    onChange={handleChange}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton onClick={togglePasswordVisibility}>
                            {showPassword ? <VisibilityOff /> : <Visibility />}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                </div>
                <div>
                  <b>Mobile:</b>
                </div>
                <div>
                  <TextField
                    id="outlined-basic"
                    variant="outlined"
                    size="small"
                    type="number"
                    name='mobile'
                    value={data?.mobile}
                    onChange={handleChange}
                  />
                </div>
                <div>
                  <b>Lot-Level</b>
                </div>
                <div>
                  <FormControl
                    sx={{ minWidth: 120, maxWidth: 250 }}
                    size="small"
                  >
                    <Select
                      labelId="demo-select-small-label"
                      value={lotLevel}
                      onChange={handleLotLevelChange}
                    >
                      <MenuItem value="Select lot-level" disabled>Select lot-level</MenuItem>
                      {responseData.map((lot) => (
                        <MenuItem
                          key={lot.id}
                          value={lot.id}
                          style={{
                            whiteSpace: "nowrap",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            maxWidth: "250px",
                          }}
                        >
                          {lot.lotName && lot.lotName.length > 12 ? (
                            <Tooltip title={lot.lotName}>
                              {lot.lotName.substr(0, 12) + "..."}
                            </Tooltip>
                          ) : (
                            lot.lotName
                          )}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </div>
                <div>
                  <b>Queue:</b>
                </div>
                <div>
                  <FormControl
                    sx={{ minWidth: 120, maxWidth: 250 }}
                    size="small"
                  >
                    <Select
                      labelId="demo-select-small-label"
                      value={queueSelect}
                      onChange={handleQueueSelectChange}
                    >
                      <MenuItem value="Select queue" disabled>Select queue</MenuItem>
                      {getQueueData
                        .filter(queue => queue.lotId === lotLevel) // Filter queues by selected lot ID
                        .map((queue) => (
                          <MenuItem
                            key={queue.id}
                            value={queue.id}
                            style={{
                              whiteSpace: "nowrap",
                              overflow: "hidden",
                              textOverflow: "ellipsis",
                              maxWidth: "250px",
                            }}
                          >
                            {/* {queue.queueName} */}
                            {queue.queueName && queue.queueName.length > 12 ? (
                              <Tooltip title={queue.queueName}>
                                {queue.queueName.substr(0, 12) + "..."}
                              </Tooltip>
                            ) : (
                              queue.queueName
                            )}
                          </MenuItem>
                        ))}
                    </Select>
                  </FormControl>
                </div>
              </div>
            </DialogContent>
            <DialogActions className="p-3">
              <Button className='capitalize' variant="outlined" onClick={handleCloseAdd}>
                Cancel
              </Button>
              <Button
                variant="outlined"
                color="success"
                onClick={handleAddQueueMember}
              >
                Add
              </Button>
            </DialogActions>
          </Dialog>
        </div>
      </div>

      {/* <div className='mt-12'>
        <MaterialReactTable
          columns={columns}
          data={getData}
          enableHiding={false}
          enableDensityToggle={false}
        />
      </div> */}

      {getQueueMemberData &&
        getQueueMemberData.length > 0 &&
        getQueueMemberData.map((member, index) => (
          <Accordion className="mb-2 accordian_main" key={index}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography>{member?.name}</Typography>
              <div className="action_menu border-0 ml-auto">
                <div className="gap-2 flex ">
                  <Button
                    onClick={() => handleOpenEdit(member?.id)}
                    variant="outlined"
                    size="small"
                    color="primary"
                  >
                    Edit
                  </Button>
                </div>
              </div>
            </AccordionSummary>
            <AccordionDetails className="p-0">
              <div className="w-full rounded datatable" style={{ boxShadow: '3px 4px 10px 0px rgba(230, 230, 230, 1)', WebkitBoxShadow: '3px 4px 10px 0px rgba(230, 230, 230, 1)' }}>
                <table className='w-full'>
                  <thead>
                    <tr>
                      <th className="p-3 text-left">Token No.</th>
                      <th className="p-3 text-left">Name</th>
                      <th className="p-3 text-left">Queue</th>
                      <th className="p-3 text-left">Lots-Level</th>
                    </tr>
                  </thead>
                  <tbody>
                    {getData &&
                      getData.length > 0 &&
                      getData.filter(data => data.queuememberId === member.id).map((data, index) => (
                        <tr key={index}>
                          <td className='text-left'>
                            {data?.ticketNumber}
                          </td>
                          <td className='text-left'>
                            {data?.queueOwner?.name}
                          </td>
                          <td className='text-left'>
                            {data?.queue?.queueName}
                          </td>
                          <td className='text-left'>
                            {data?.lot?.lotName}
                          </td>
                        </tr>
                      ))}
                  </tbody>
                </table>
              </div>
            </AccordionDetails>
          </Accordion>
        ))}
      <Dialog
        open={edit}
        onClose={handleCloseEdit}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title"></DialogTitle>
        <DialogContent>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
            <div>
              <b>Member Name:</b>
            </div>
            <div>
              <TextField
                id="outlined-basic"
                variant="outlined"
                size="small"
                type="text"
                name='name'
                value={data?.name}
                onChange={handleChange}
              />
            </div>
            <div>
              <b>Email:</b>
            </div>
            <div>
              <TextField
                id="outlined-basic"
                variant="outlined"
                size="small"
                type="email"
                name='email'
                disabled
                value={data?.email}
                onChange={handleChange}
              />
            </div>
            <div>
              <b>Password:</b>
            </div>
            <div>
              <TextField
                id="outlined-basic"
                variant="outlined"
                size="small"
                type={showPassword ? 'text' : 'password'}
                name='password'
                // value={data?.password}
                onChange={handleChange}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton onClick={togglePasswordVisibility}>
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </div>
            <div>
              <b>Mobile:</b>
            </div>
            <div>
              <TextField
                id="outlined-basic"
                variant="outlined"
                size="small"
                type="number"
                name='mobile'
                value={data?.mobile}
                onChange={handleChange}
              />
            </div>
            <div>
              <b>Lot-Level</b>
            </div>
            <div>
              <FormControl
                sx={{ minWidth: 120, maxWidth: 250 }}
                size="small"
              >
                <Select
                  labelId="demo-select-small-label"
                  value={lotLevel}
                  onChange={handleLotLevelChange}
                >
                  <MenuItem value="Select lot-level" disabled>Select lot-level</MenuItem>
                  {responseData.map((lot) => (
                    <MenuItem
                      key={lot.id}
                      value={lot.id}
                      style={{
                        whiteSpace: "nowrap",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        maxWidth: "250px",
                      }}
                    >
                      {lot.lotName && lot.lotName.length > 12 ? (
                        <Tooltip title={lot.lotName}>
                          {lot.lotName.substr(0, 12) + "..."}
                        </Tooltip>
                      ) : (
                        lot.lotName
                      )}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div>
            <div>
              <b>Queue:</b>
            </div>
            <div>
              <FormControl
                sx={{ minWidth: 120, maxWidth: 250 }}
                size="small"
              >
                <Select
                  labelId="demo-select-small-label"
                  value={queueSelect}
                  onChange={handleQueueSelectChange}
                >
                  <MenuItem value="Select queue" disabled>Select queue</MenuItem>
                  {getQueueData
                    .filter(queue => queue.lotId === lotLevel) // Filter queues by selected lot ID
                    .map((queue) => (
                      <MenuItem
                        key={queue.id}
                        value={queue.id}
                        style={{
                          whiteSpace: "nowrap",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                          maxWidth: "250px",
                        }}
                      >
                        {queue.queueName && queue.queueName.length > 12 ? (
                          <Tooltip title={queue.queueName}>
                            {queue.queueName.substr(0, 12) + "..."}
                          </Tooltip>
                        ) : (
                          queue.queueName
                        )}
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>
            </div>
          </div>
        </DialogContent>
        <DialogActions className="p-3">
          <Button variant="outlined" onClick={handleCloseEdit}>
            Cancel
          </Button>
          <Button
            variant="outlined"
            color="success"
            onClick={handleEditQueueMember}
          >
            Update
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  )
}

export default QueueMembers