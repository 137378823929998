import admin from "./admin";
import auth from "./auth";
import superAdmin from "./superAdmin";

const api = {
  ...admin,
  ...auth,
  ...superAdmin,
};
export default api;
