import React, { useEffect, useState } from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter as Router, Route, Routes, useLocation, Navigate, useNavigate } from 'react-router-dom';
import Login from './app/pages/auth/login';
import SideBar from './components/sidebar';
import AdminDashboard from './app/pages/admin/adminDashboard';
import Sessions from './app/pages/admin/sessions';
import LotLevels from './app/pages/admin/lot-levels';
import Queues from './app/pages/admin/queues';
import QueueOwners from './app/pages/admin/queue-owners';
import QueueMembers from './app/pages/admin/queue-members';
import RebalanceQueues from './app/pages/admin/rebalance-queues';
import Profile from './app/pages/admin/profile';
import Dashboard from './app/pages/superAdmin/dashboard';
import Reports from './app/pages/superAdmin/reports';
import PaymentReports from './app/pages/superAdmin/payment-report';
import TermsAndConditions from './app/pages/terms-and-conditions';
import ProfileEdit from './app/pages/admin/profile-edit';
import PrivacyPolicy from './app/pages/privacy-policy';
import RefundPolicy from './app/pages/refund-policy';
import { Box, CssBaseline } from '@mui/material';
import { getToken } from './services/api/utils';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { getRole } from './services/api/utils';
import ForgotPassword from './app/pages/auth/forgotPassword';

const ProtectedRoute = ({ children }) => {
  const token = getToken();
  const role = getRole();
  const location = useLocation();

  // Check if the user is logged in
  if (!token && location.pathname !== '/' && location.pathname !== '/login') {
    return <Navigate to="/login" />;
  }

  // Check if the user is authorized based on role
  if (role) {
    const isAdminRoute = location.pathname.startsWith('/admin');
    const isSuperAdminRoute = location.pathname.startsWith('/superadmin');

    if ((role === 'admin' && !isAdminRoute) || (role === 'super_admin' && !isSuperAdminRoute)) {
      // Redirect to appropriate dashboard based on role
      return role === 'admin' ? <Navigate to="/admin/dashboard" /> : <Navigate to="/superadmin/dashboard" />;
    }
  }

  return children;
};

const Layout = () => {
  const [token, setToken] = useState(getToken());
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    const fetchedToken = getToken();
    if (!fetchedToken && location.pathname !== '/' && location.pathname !== '/login' && location.pathname !== '/forgot-password' && location.pathname !== '/terms-and-conditions' && location.pathname !== '/privacy-policy' && location.pathname !== '/refund-policy') {
      navigate('/login');
    } else {
      setToken(fetchedToken);
    }
  }, [location, navigate]);

  const isPublicRoute = location.pathname === '/' || location.pathname === '/login' || location.pathname === '/forgot-password' || location.pathname === '/terms-and-conditions' || location.pathname === '/privacy-policy' || location.pathname === '/refund-policy';

  return (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      {!isPublicRoute && token && <SideBar />}
      <Box component="main" sx={{ flexGrow: 1, flex: 1, marginTop: '50px', p: 3 }}>
        <Routes>
          <Route exact path="/" element={<App />} />
          <Route exact path="/login" element={<Login />} />
          <Route exact path="/forgot-password" element={<ForgotPassword />} />
          <Route exact path="/admin/dashboard" element={<ProtectedRoute><AdminDashboard /></ProtectedRoute>} />
          <Route exact path="/admin/sessions" element={<ProtectedRoute><Sessions /></ProtectedRoute>} />
          <Route exact path="/admin/lotLevels" element={<ProtectedRoute><LotLevels /></ProtectedRoute>} />
          <Route exact path="/admin/queues" element={<ProtectedRoute><Queues /></ProtectedRoute>} />
          <Route exact path="/admin/queueOwners" element={<ProtectedRoute><QueueOwners /></ProtectedRoute>} />
          <Route exact path="/admin/queueMembers" element={<ProtectedRoute><QueueMembers /></ProtectedRoute>} />
          <Route exact path="/admin/rebalanceQueues" element={<ProtectedRoute><RebalanceQueues /></ProtectedRoute>} />
          <Route exact path="/admin/profile" element={<ProtectedRoute><Profile /></ProtectedRoute>} />
          <Route exact path="/admin/profileEdit/:id" element={<ProtectedRoute><ProfileEdit /></ProtectedRoute>} />
          <Route exact path="/terms-and-conditions" element={<TermsAndConditions />} />
          <Route exact path="/privacy-policy" element={<PrivacyPolicy />} />
          <Route exact path="/refund-policy" element={<RefundPolicy />} />
          <Route exact path="/superadmin/dashboard" element={<ProtectedRoute><Dashboard /></ProtectedRoute>} />
          <Route exact path="/superadmin/reports" element={<ProtectedRoute><Reports /></ProtectedRoute>} />
          <Route exact path="/superadmin/payment_report" element={<ProtectedRoute><PaymentReports /></ProtectedRoute>} />
        </Routes>
        <ToastContainer closeOnClick />
      </Box>
    </Box>
  );
};

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <Router>
    <React.StrictMode>
      <Layout />
    </React.StrictMode>
  </Router>
);

reportWebVitals();
