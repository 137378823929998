import React, { useEffect, useState } from 'react';
import AddIcon from "@mui/icons-material/Add";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  MenuItem,
  Select,
  TextField,
  Tooltip,
} from "@mui/material";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import { MaterialReactTable } from "material-react-table";
import api from '../../../services/api';
import { toast } from 'react-toastify';
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { IconButton, InputAdornment } from '@mui/material';

const QueueOwners = () => {
  const [getData, setGetData] = useState([]);
  console.log("getData-========", getData);
  const [add, setAdd] = useState(false);
  const [data, setData] = useState({
    name: "",
    mobile: "",
    email: "",
    password: "",
    role: "",
    isVerified: true,
    lotId: null,
    queueId: null,
    is_disable: false
  });
  const [lotLevel, setLotLevel] = useState("Select lot-level");
  const [queueSelect, setQueueSelect] = useState("Select queue");
  const [responseData, setResponseData] = useState([]);
  const [getQueueData, setGetQueueData] = useState([]);
  const [selectedLotId, setSelectedLotId] = useState(null);
  const [edit, setEdit] = useState(false);
  const [editingQueueOwnerId, setEditingQueueOwnerId] = useState(null);
  const [disable, setDisable] = useState(false);
  const [indx, setIndx] = useState(null);
  const [getQueueOwnerData, setGetQueueOwnerData] = useState([]);
  const [showPassword, setShowPassword] = useState(false);

  const getAllData = async () => {
    try {
      const response = await api.getAllTableDataInOwner();
      if (response) {
        setGetData(response?.data);
      } else {
        console.error("Failed to fetch admins");
      }
    } catch (error) {
      console.error("Error fetching admins:", error);
    }
  };

  const fetchQueueOwnersData = async () => {
    try {
      const apiRes = await api.getQueueOwners();
      const res = apiRes?.data
      if (res) {
        setGetQueueOwnerData(res);
      }
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  useEffect(() => {
    getAllData();
    fetchQueueOwnersData();
  }, []);

  // const columns = [
  //   {
  //     header: "Name",
  //     id: "name",
  //     accessorKey: "queueOwner.name",
  //   },
  //   {
  //     header: "Email",
  //     id: "email",
  //     accessorKey: "queueOwner.email",
  //   },
  //   {
  //     header: "Mobile",
  //     id: "mobile",
  //     accessorKey: "queueOwner.mobile",
  //   },
  //   {
  //     header: "Queue",
  //     id: "queueName",
  //     accessorKey: "queue.queueName",
  //   },
  //   {
  //     header: "Lot",
  //     id: "lotName",
  //     accessorKey: "lot.lotName",
  //   },
  //   {
  //     header: "Action",
  //     id: "action",
  //     accessorKey: "action",
  //     Cell: ({ row }) => {
  //       return (
  //         <div className="gap-2 flex ">
  //           <Button
  //             onClick={() => handleOpenEdit(row?.original?.queueOwner?.id)}
  //             variant="outlined"
  //             size="small"
  //             color="primary"
  //           >
  //             Edit
  //           </Button>
  //           <Button
  //             onClick={() => handleOpenDisable(row.index, row?.original?.queueOwner?.id)}
  //             variant="outlined"
  //             size="small"
  //             color="secondary"
  //           >
  //             {row?.original?.is_disable ? "Enable" : "Disable"}
  //           </Button>
  //         </div>
  //       );
  //     },
  //   },
  // ];

  const handleOpenAdd = () => setAdd(true);
  const handleCloseAdd = () => setAdd(false);

  const handleChange = (event) => {
    const { name, value } = event.target;
    setData({
      ...data,
      [name]: value
    });
  };

  const handleLotLevelChange = (event) => {
    setLotLevel(event.target.value);
    setData({
      ...data,
      lotId: event.target.value,
    });
  };

  const handleQueueSelectChange = (event) => {
    setQueueSelect(event.target.value);
    setData({
      ...data,
      queueId: event.target.value,
    });
  };

  const resetForm = () => {
    setData({
      ...data,
      name: "",
      mobile: "",
      email: "",
      password: "",
    });
    setLotLevel(null);
    setQueueSelect(null);
  };

  const handleAddQueueOwner = async () => {
    if (!data.lotId) {
      toast.error("Please select a lot-level");
      return;
    }
    if (!data.queueId) {
      toast.error("Please select a queue");
      return;
    }
    try {
      data.role = 'queue_owner';
      const res = await api.signUpForOwner(data);
      console.log("🚀 ~ handleLogin ~ res:", res);
      if (res.response_code === 200) {
        toast.success("Registration Successfully ");
        localStorage.setItem("user", JSON.stringify(res));
        handleCloseAdd();
        resetForm();
        fetchQueueOwnersData();
      }
    } catch (error) {
      toast.error(error?.message);
      console.log("🚀 ~ handleLogin ~ error:", error);
    }
  };

  useEffect(() => {
    const fetchLotData = async () => {
      try {
        const apiRes = await api.getLotByUser();
        const res = apiRes?.data
        if (res) {
          setResponseData(res);
        }
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };
    fetchLotData();
  }, []);

  useEffect(() => {
    const fetchQueueData = async () => {
      try {
        const apiRes = await api.getQueueByUser();
        const res = apiRes?.data
        if (res) {
          setGetQueueData(res);
        }
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };
    fetchQueueData();
  }, []);

  const handleOpenEdit = async (queueOwnerId) => {
    console.log("getQueueData--------------================", getQueueData);
    const ownerToEdit = getQueueData.find((queue) => queue?.queueownerId === queueOwnerId);
    console.log("ownerToEdit==================-------------------", ownerToEdit);
    // Find the lot to edit based on the provided queueOwnerId
    try {
      const response = await api.getQueueOwner(queueOwnerId);
      console.log("response----------123=========", response);
      if (response) {
        setEditingQueueOwnerId(queueOwnerId); // Store the ID of the lot being edited
        setData({
          name: response?.data?.name,
          // password: '',
          email: response?.data?.email,
          mobile: response?.data?.mobile,
        });
        setLotLevel(response.data.lotId);
        setQueueSelect(response.data.queueId);
        setEdit(true); // Open the edit dialog
      } else {
        console.error("Failed to fetch data");
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const handleCloseEdit = () => {
    setEdit(false);
    setEditingQueueOwnerId(null);
    resetForm();
  };

  const handleEditQueueOwner = async () => {
    if (!data.name || !data.email || !data.mobile) {
      toast.error("All fields are required");
      return;
    }
    if (!data.name.trim()) {
      toast.error("Queue Owner Name should not be empty or only spaces");
      return;
    }
    if (editingQueueOwnerId) {
      try {
        const update = await api.updateQueueOwner(data, editingQueueOwnerId);
        if (update.response_code === 200) {
          toast.success("Updated Successfully");
          handleCloseEdit();
          fetchQueueOwnersData();
        }
      } catch (error) {
        toast.error("Failed to Update queueowner");
      }
    }
  };

  const handleOpenDisable = (index, queueOwnerId) => {
    setIndx(index);
    setEditingQueueOwnerId(queueOwnerId);
    setDisable(true);
  };
  const handleCloseDisable = () => setDisable(false);

  const toggleIsDisable = async (index, id) => {
    const updatedOwnerData = [...getQueueOwnerData];
    updatedOwnerData[index].is_disable = !updatedOwnerData[index].is_disable; // Toggle the is_disable property
    setGetQueueOwnerData(updatedOwnerData); // Update the state with the modified array

    const queueStatus = updatedOwnerData[index].is_disable ? "disabled" : "enabled";
    try {
      const update = await api.updateQueueOwner({ is_disable: updatedOwnerData[index].is_disable }, id);
      if (update.response_code === 200) {
        toast.success(`Queue owner ${queueStatus} successfully`);
        handleCloseDisable();
        fetchQueueOwnersData();
      }
    } catch (error) {
      toast.error("Failed to Update Queue Owner");
    }
  };

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  return (
    <>
      <div>
        <div>
          <div className="my-6 ml-auto text-black bg-white rounded-[35px] w-fit flex items-center border border-[#ababab] py-[8px] px-[10px] cursor-pointer"
            onClick={handleOpenAdd}>
            <div>
              <AddIcon className='text-white bg-[#528dfe] rounded-full mr-4 text-[28px]' />
            </div>
            <div className='font-[500]'>
              Add new Queue Owner
            </div>
          </div>
          <Dialog
            open={add}
            onClose={handleCloseAdd}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title"></DialogTitle>
            <DialogContent>
              <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                <div>
                  <b>Owner Name:</b>
                </div>
                <div>
                  <TextField
                    id="outlined-basic"
                    variant="outlined"
                    size="small"
                    type="text"
                    name='name'
                    value={data?.name}
                    onChange={handleChange}
                  />
                </div>
                <div>
                  <b>Email:</b>
                </div>
                <div>
                  <TextField
                    id="outlined-basic"
                    variant="outlined"
                    size="small"
                    type="email"
                    name='email'
                    value={data?.email}
                    onChange={handleChange}
                  />
                </div>
                <div>
                  <b>Password:</b>
                </div>
                <div>
                  <TextField
                    id="outlined-basic"
                    variant="outlined"
                    size="small"
                    type={showPassword ? 'text' : 'password'}
                    name='password'
                    value={data?.password}
                    onChange={handleChange}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton onClick={togglePasswordVisibility}>
                            {showPassword ? <VisibilityOff /> : <Visibility />}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                </div>
                <div>
                  <b>Mobile:</b>
                </div>
                <div>
                  <TextField
                    id="outlined-basic"
                    variant="outlined"
                    size="small"
                    type="number"
                    name='mobile'
                    value={data?.mobile}
                    onChange={handleChange}
                  />
                </div>
                <div>
                  <b>Assign Lot:</b>
                </div>
                <div>
                  <FormControl
                    sx={{ minWidth: 120, maxWidth: 250 }}
                    size="small"
                  >
                    <Select
                      labelId="demo-select-small-label"
                      value={lotLevel}
                      onChange={handleLotLevelChange}
                    >
                      <MenuItem value="Select lot-level" disabled>Select lot-level</MenuItem>
                      {responseData.map((lot) => (
                        <MenuItem
                          key={lot.id}
                          value={lot.id}
                          style={{
                            whiteSpace: "nowrap",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            maxWidth: "250px",
                          }}
                        >
                          {lot.lotName && lot.lotName.length > 12 ? (
                            <Tooltip title={lot.lotName}>
                              {lot.lotName.substr(0, 12) + "..."}
                            </Tooltip>
                          ) : (
                            lot.lotName
                          )}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </div>
                <div>
                  <b>Assign Queue:</b>
                </div>
                <div>
                  <FormControl
                    sx={{ minWidth: 120, maxWidth: 250 }}
                    size="small"
                  >
                    <Select
                      labelId="demo-select-small-label"
                      value={queueSelect}
                      onChange={handleQueueSelectChange}
                    >
                      <MenuItem value="Select queue" disabled>Select queue</MenuItem>
                      {getQueueData
                        .filter(queue => queue.lotId === lotLevel) // Filter queues by selected lot ID
                        .map((queue) => (
                          <MenuItem
                            key={queue.id}
                            value={queue.id}
                            style={{
                              whiteSpace: "nowrap",
                              overflow: "hidden",
                              textOverflow: "ellipsis",
                              maxWidth: "250px",
                            }}
                          >
                            {queue.queueName && queue.queueName.length > 12 ? (
                              <Tooltip title={queue.queueName}>
                                {queue.queueName.substr(0, 12) + "..."}
                              </Tooltip>
                            ) : (
                              queue.queueName
                            )}
                          </MenuItem>
                        ))}
                    </Select>
                  </FormControl>
                </div>
              </div>
            </DialogContent>
            <DialogActions className="p-3">
              <Button className='capitalize' variant="outlined" onClick={handleCloseAdd}>
                Cancel
              </Button>
              <Button
                variant="outlined"
                color="success"
                onClick={handleAddQueueOwner}
              >
                Add
              </Button>
            </DialogActions>
          </Dialog>
        </div>

        {/* <div className='mt-12'>
        {Array.isArray(getData) && (
          <MaterialReactTable
            columns={columns}
            data={getData}
            enableHiding={false}
            enableDensityToggle={false}
          />
        )}
      </div> */}

        {getQueueOwnerData &&
          getQueueOwnerData.length > 0 &&
          getQueueOwnerData.map((owner, index) => (
            <Accordion className="mb-2 accordian_main" key={index}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Typography>{owner?.name}</Typography>
                <div className="action_menu border-0 ml-auto">
                  <div className="gap-2 flex ">
                    <Button
                      onClick={() => handleOpenEdit(owner?.id)}
                      variant="outlined"
                      size="small"
                      color="primary"
                    >
                      Edit
                    </Button>
                    <Button
                      onClick={() => handleOpenDisable(index, owner?.id)}
                      variant="outlined"
                      size="small"
                      color="secondary"
                    >
                      {owner?.is_disable ? "Enable" : "Disable"}
                    </Button>
                  </div>
                </div>
              </AccordionSummary>
              <AccordionDetails className="p-0">
                <div className="w-full rounded datatable" style={{ boxShadow: '3px 4px 10px 0px rgba(230, 230, 230, 1)', WebkitBoxShadow: '3px 4px 10px 0px rgba(230, 230, 230, 1)' }}>
                  <table className='w-full'>
                    <thead>
                      <tr>
                        <th className="p-3 text-left">Name</th>
                        <th className="p-3 text-left">Email</th>
                        <th className="p-3 text-left">Mobile</th>
                        <th className="p-3 text-left">Queue</th>
                        <th className="p-3 text-left">Lot</th>
                      </tr>
                    </thead>
                    <tbody>
                      {getData &&
                        getData.length > 0 &&
                        getData.filter(data => data.queueownerId === owner.id).map((data, index) => (
                          <tr key={index}>
                            <td className='text-left'>
                              {data?.queueMember?.name}
                            </td>
                            <td className='text-left'>
                              {data?.queueMember?.email}
                            </td>
                            <td className='text-left'>
                              {data?.queueMember?.mobile}
                            </td>
                            <td className='text-left'>
                              {data?.queue?.queueName}
                            </td>
                            <td className='text-left'>
                              {data?.lot?.lotName}
                            </td>
                          </tr>
                        ))}
                    </tbody>
                  </table>
                </div>
              </AccordionDetails>
            </Accordion>
          ))}
      </div>
      <Dialog
        open={edit}
        onClose={handleCloseEdit}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title"></DialogTitle>
        <DialogContent>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
            <div>
              <b>Owner Name:</b>
            </div>
            <div>
              <TextField
                id="outlined-basic"
                variant="outlined"
                size="small"
                type="text"
                name='name'
                value={data?.name}
                onChange={handleChange}
              />
            </div>
            <div>
              <b>Email:</b>
            </div>
            <div>
              <TextField
                id="outlined-basic"
                variant="outlined"
                size="small"
                type="email"
                name='email'
                disabled
                value={data?.email}
                onChange={handleChange}
              />
            </div>
            <div>
              <b>Password:</b>
            </div>
            <div>
              <TextField
                id="outlined-basic"
                variant="outlined"
                size="small"
                type={showPassword ? 'text' : 'password'}
                name='password'
                // value={data?.password}
                onChange={handleChange}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton onClick={togglePasswordVisibility}>
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </div>
            <div>
              <b>Mobile:</b>
            </div>
            <div>
              <TextField
                id="outlined-basic"
                variant="outlined"
                size="small"
                type="number"
                name='mobile'
                value={data?.mobile}
                onChange={handleChange}
              />
            </div>
            <div>
              <b>Assign Lot:</b>
            </div>
            <div>
              <FormControl
                sx={{ minWidth: 120, maxWidth: 250 }}
                size="small"
              >
                <Select
                  labelId="demo-select-small-label"
                  value={lotLevel}
                  onChange={handleLotLevelChange}
                >
                  <MenuItem value="Select lot-level" disabled>Select lot-level</MenuItem>
                  {responseData.map((lot) => (
                    <MenuItem
                      key={lot.id}
                      value={lot.id}
                      style={{
                        whiteSpace: "nowrap",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        maxWidth: "250px",
                      }}
                    >
                      {lot.lotName && lot.lotName.length > 12 ? (
                        <Tooltip title={lot.lotName}>
                          {lot.lotName.substr(0, 12) + "..."}
                        </Tooltip>
                      ) : (
                        lot.lotName
                      )}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div>
            <div>
              <b>Assign Queue:</b>
            </div>
            <div>
              <FormControl
                sx={{ minWidth: 120, maxWidth: 250 }}
                size="small"
              >
                <Select
                  labelId="demo-select-small-label"
                  value={queueSelect}
                  onChange={handleQueueSelectChange}
                >
                  <MenuItem value="Select queue" disabled>Select queue</MenuItem>
                  {getQueueData
                    .filter(queue => queue.lotId === lotLevel) // Filter queues by selected lot ID
                    .map((queue) => (
                      <MenuItem
                        key={queue.id}
                        value={queue.id}
                        style={{
                          whiteSpace: "nowrap",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                          maxWidth: "250px",
                        }}
                      >
                        {queue.queueName && queue.queueName.length > 12 ? (
                          <Tooltip title={queue.queueName}>
                            {queue.queueName.substr(0, 12) + "..."}
                          </Tooltip>
                        ) : (
                          queue.queueName
                        )}
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>
            </div>
          </div>
        </DialogContent>
        <DialogActions className="p-3">
          <Button variant="outlined" onClick={handleCloseEdit}>
            Cancel
          </Button>
          <Button
            variant="outlined"
            color="success"
            onClick={handleEditQueueOwner}
          >
            Update
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={disable}
        onClose={handleCloseDisable}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title"></DialogTitle>
        <DialogContent>
          <Typography
            id="modal-modal-description"
            sx={{ mt: 2, fontWeight: "600", mb: 2 }}
          >
            <span style={{ color: "red", fontWeight: "400" }}>Note: </span>
            Existing users from the Queue will be Processed as it is.
          </Typography>
          <div className="confirm_but">
            <Button
              variant="outlined"
              onClick={() => toggleIsDisable(indx, editingQueueOwnerId)}
            >
              Confirm
            </Button>
          </div>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default QueueOwners;
