import axios from "axios";
import { toast } from "react-toastify";

export const API = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL,
  headers: {
    "Content-Type": "application/json",
  },
});
export const APIFORMDATA = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL,
  headers: {
    "Content-Type": "multipart/form-data",
  },
});

export const processFormData = (object) => {
  const formData = new FormData();
  for (const key in object) {
    const value = object[key];
    formData.append(key, value);
  }
  return formData;
};

export const handleResponse = (response) => {
  console.log("response========---------", response);
  console.log("response?.response_code========---------", response?.data?.response_code);
  if (response?.response?.data?.response_code === 202) {
    toast.error(response?.response?.data.message);
    return false;
  }
  if (response?.response?.data?.response_code === 201) {
    return toast.success(response?.response?.data.message);
  } else if (response?.response?.data?.response_code === 500) {
    toast.error(response?.response?.data.message);
  } else if (response?.response?.data?.response_code === 400) {
    return toast.error(response?.response?.data?.message);
  } else if (response?.response?.data?.response_code === 401) {
    return toast.error(response?.response?.data?.message);
  } else if (response?.data?.response_code === 200) {
    return response?.data;
  } else {
    toast.error(response?.response?.data.message || "Something went wrong. Please contact server admin.");
  }
  return false;
};

export const getQR = () => {
  const session = localStorage?.getItem("loginData");
  if (session) return JSON.parse(session)?.qrCode;
  return false;
};

export const getRole = () => {
  const session = localStorage?.getItem("loginData");
  if (session) return JSON.parse(session)?.role;
  return false;
};
export const getToken = () => {
  const session = localStorage?.getItem("loginData");
  if (session) return JSON.parse(session)?.token;
  return false;
};
export const getUserId = () => {
  const session = localStorage?.getItem("loginData");
  if (session) return JSON.parse(session)?.id;
  return false;
};
export const getSessionCost = () => {
  const session = localStorage?.getItem("loginData");
  if (session) return JSON.parse(session)?.sessionCost;
  return false;
};
export const getUserName = () => {
  const session = localStorage?.getItem("loginData");
  if (session) return JSON.parse(session)?.name;
  return false;
};
export const getUserEmail = () => {
  const session = localStorage?.getItem("loginData");
  if (session) return JSON.parse(session)?.email;
  return false;
};


export const getEmail = async () => {
  const session = localStorage?.getItem("email");
  if (session) return (session);
  return false;
};
