import { handleResponse, API, getToken, getUserId } from "./utils";

const admin = {
  createSession: async (data) => {
    const token = await getToken();
    const userId = getUserId();
    let response = null;
    try {
      response = await API.post(`/session/add/${userId}`, data, {
        params: {},
        headers: { Authorization: "Bearer: " + token },
      });
    } catch (e) {
      response = e;
    }
    return handleResponse(response);
  },
  createLot: async (data) => {
    const token = await getToken();
    const userId = getUserId();
    let response = null;
    try {
      response = await API.post(`/lot/add/${userId}`, data, {
        params: {},
        headers: { Authorization: "Bearer: " + token },
      });
    } catch (e) {
      response = e;
    }
    return handleResponse(response);
  },
  getLotByUser: async () => {
    const token = await getToken();
    const userId = getUserId();
    let response = null;
    try {
      response = await API.get(`/lot/get/all/${userId}`, {
        params: {},
        headers: { Authorization: "Bearer: " + token },
      });
    } catch (e) {
      response = e;
    }
    return handleResponse(response);
  },
  createQueue: async (data) => {
    const token = await getToken();
    const userId = getUserId();
    let response = null;
    try {
      response = await API.post(`/queue/add/${userId}`, data, {
        params: {},
        headers: { Authorization: "Bearer: " + token },
      });
    } catch (e) {
      response = e;
    }
    return handleResponse(response);
  },
  getQueueByUser: async () => {
    const token = await getToken();
    const userId = getUserId();
    let response = null;
    try {
      response = await API.get(`/queue/get/all/${userId}`, {
        params: {},
        headers: { Authorization: "Bearer: " + token },
      });
    } catch (e) {
      response = e;
    }
    return handleResponse(response);
  },
  createQueueOwner: async (data) => {
    const token = await getToken();
    const userId = getUserId();
    let response = null;
    try {
      response = await API.post(`/queueOwner/add/${userId}`, data, {
        params: {},
        headers: { Authorization: "Bearer: " + token },
      });
    } catch (e) {
      response = e;
    }
    return handleResponse(response);
  },
  createQueueMember: async (data) => {
    const token = await getToken();
    const userId = getUserId();
    let response = null;
    try {
      response = await API.post(`/queueMember/add/${userId}`, data, {
        params: {},
        headers: { Authorization: "Bearer: " + token },
      });
    } catch (e) {
      response = e;
    }
    return handleResponse(response);
  },
  getAllTableDataWithDate: async (date) => {
    const token = await getToken();
    const userId = getUserId();
    let response = null;
    try {
      response = await API.get(`/ticket/getDetails/byDate/${userId}`, {
        params: { date },
        headers: { Authorization: "Bearer: " + token },
      });
    } catch (e) {
      response = e;
    }
    return handleResponse(response);
  },
  getAllTableData: async (date) => {
    const token = await getToken();
    const userId = getUserId();
    let response = null;
    try {
      response = await API.get(`/ticket/getDetails/${userId}`, {
        params: {},
        headers: { Authorization: "Bearer: " + token },
      });
    } catch (e) {
      response = e;
    }
    return handleResponse(response);
  },
  getAllTableDataInOwner: async (date) => {
    const token = await getToken();
    const userId = getUserId();
    let response = null;
    try {
      response = await API.get(`/ticket/getDetails/owner/${userId}`, {
        params: {},
        headers: { Authorization: "Bearer: " + token },
      });
    } catch (e) {
      response = e;
    }
    return handleResponse(response);
  },
  getAllTableDataInQueue: async (date) => {
    const token = await getToken();
    const userId = getUserId();
    let response = null;
    try {
      response = await API.get(`/ticket/getDetails/queue/${userId}`, {
        params: {},
        headers: { Authorization: "Bearer: " + token },
      });
    } catch (e) {
      response = e;
    }
    return handleResponse(response);
  },
  getAllDataInQueue: async (date) => {
    const token = await getToken();
    const userId = getUserId();
    let response = null;
    try {
      response = await API.get(`/queue/details/get/${userId}`, {
        params: {},
        headers: { Authorization: "Bearer: " + token },
      });
    } catch (e) {
      response = e;
    }
    return handleResponse(response);
  },
  getTicketData: async () => {
    const token = await getToken();
    const userId = getUserId();
    let response = null;
    try {
      response = await API.get(`/ticket/getTicketDetailsByAdminId/${userId}`, {
        params: {},
        headers: { Authorization: "Bearer: " + token },
      });
    } catch (e) {
      response = e;
    }
    return handleResponse(response);
  },
  // getAllTableDataByDate: async (date) => {
  //   const token = await getToken();
  //   const userId = getUserId();
  //   let response = null;
  //   try {
  //     response = await API.get(`/ticket/getDetails/byDate/${userId}`, {
  //       params: { date },
  //       headers: { Authorization: "Bearer: " + token },
  //     });
  //   } catch (e) {
  //     response = e;
  //   }
  //   return handleResponse(response);
  // },
  getLot: async (id) => {
    const token = await getToken();
    let response = null;
    try {
      response = await API.get(`/lot/get/${id}`, {
        params: {},
        headers: { Authorization: "Bearer: " + token },
      });
    } catch (e) {
      response = e;
    }
    return handleResponse(response);
  },
  updateLot: async (data, id) => {
    const token = await getToken();
    let response = null;
    try {
      response = await API.put(`/lot/update/${id}`, data, {
        params: {},
        headers: { Authorization: "Bearer: " + token },
      });
    } catch (e) {
      response = e;
    }
    return handleResponse(response);
  },
  getQueue: async (id) => {
    const token = await getToken();
    let response = null;
    try {
      response = await API.get(`/queue/get/${id}`, {
        params: {},
        headers: { Authorization: "Bearer: " + token },
      });
    } catch (e) {
      response = e;
    }
    return handleResponse(response);
  },
  updateQueue: async (data, id) => {
    const token = await getToken();
    let response = null;
    try {
      response = await API.put(`/queue/update/${id}`, data, {
        params: {},
        headers: { Authorization: "Bearer: " + token },
      });
    } catch (e) {
      response = e;
    }
    return handleResponse(response);
  },
  getQueueOwner: async (id) => {
    const token = await getToken();
    let response = null;
    try {
      response = await API.get(`/user/get/owner/${id}`, {
        params: {},
        headers: { Authorization: "Bearer: " + token },
      });
    } catch (e) {
      response = e;
    }
    return handleResponse(response);
  },
  getQueueMember: async (id) => {
    const token = await getToken();
    let response = null;
    try {
      response = await API.get(`/user/get/member/${id}`, {
        params: {},
        headers: { Authorization: "Bearer: " + token },
      });
    } catch (e) {
      response = e;
    }
    return handleResponse(response);
  },
  updateQueueOwner: async (data, id) => {
    const token = await getToken();
    let response = null;
    try {
      response = await API.put(`/user/update/owner/${id}`, data, {
        params: {},
        headers: { Authorization: "Bearer: " + token },
      });
    } catch (e) {
      response = e;
    }
    return handleResponse(response);
  },
  updateQueueMember: async (data, id) => {
    const token = await getToken();
    let response = null;
    try {
      response = await API.put(`/user/update/queueMember/${id}`, data, {
        params: {},
        headers: { Authorization: "Bearer: " + token },
      });
    } catch (e) {
      response = e;
    }
    return handleResponse(response);
  },
  getAdmin: async (id) => {
    const token = await getToken();
    let response = null;
    try {
      response = await API.get(`/user/get/admin/${id}`, {
        params: {},
        headers: { Authorization: "Bearer: " + token },
      });
    } catch (e) {
      response = e;
    }
    return handleResponse(response);
  },
  updateAdmin: async (data, id) => {
    const token = await getToken();
    let response = null;
    try {
      response = await API.put(`/user/update/admin/${id}`, data, {
        params: {},
        headers: { Authorization: "Bearer: " + token },
      });
    } catch (e) {
      response = e;
    }
    return handleResponse(response);
  },
  handlePayment: async (data) => {
    const token = await getToken();
    const userId = getUserId();
    let response = null;
    try {
      response = await API.post(`/payment/initiate-payment`, data, {
        params: {userId},
        headers: { Authorization: "Bearer: " + token },
      });
    } catch (e) {
      response = e;
    }
    return handleResponse(response);
  },
  getCounts: async (date) => {
    const token = await getToken();
    const userId = getUserId();
    let response = null;
    try {
      response = await API.get(`/lot/all/data/count/${userId}`, {
        params: { date }, // Pass the date range as a parameter
        headers: { Authorization: "Bearer: " + token },
      });
    } catch (e) {
      response = e;
    }
    return handleResponse(response);
  },
  getSessionByUser: async () => {
    const token = await getToken();
    const userId = getUserId();
    let response = null;
    try {
      response = await API.get(`/session/get/all/${userId}`, {
        params: {},
        headers: { Authorization: "Bearer: " + token },
      });
    } catch (e) {
      response = e;
    }
    return handleResponse(response);
  },
  getQueueOwners: async () => {
    const token = await getToken();
    const userId = getUserId();
    let response = null;
    try {
      response = await API.get(`/user/get/all/owners/${userId}`, {
        params: {},
        headers: { Authorization: "Bearer: " + token },
      });
    } catch (e) {
      response = e;
    }
    return handleResponse(response);
  },
  getQueueMembers: async () => {
    const token = await getToken();
    const userId = getUserId();
    let response = null;
    try {
      response = await API.get(`/user/get/all/members/${userId}`, {
        params: {},
        headers: { Authorization: "Bearer: " + token },
      });
    } catch (e) {
      response = e;
    }
    return handleResponse(response);
  },
  // getRebalancedData: async () => {
  //   const token = await getToken();
  //   const userId = getUserId();
  //   let response = null;
  //   try {
  //     response = await API.get(`/ticket/rebalanced/data`, {
  //       params: { userId },
  //       headers: { Authorization: "Bearer: " + token },
  //     });
  //   } catch (e) {
  //     response = e;
  //   }
  //   return handleResponse(response);
  // },
  getRebalanced: async () => {
    const token = await getToken();
    const userId = getUserId();
    let response = null;
    try {
      response = await API.get(`/ticket/rebalanced/${userId}`, {
        params: {},
        headers: { Authorization: "Bearer: " + token },
      });
    } catch (e) {
      response = e;
    }
    return handleResponse(response);
  },
  assignQueueMembers: async () => {
    const token = await getToken();
    const userId = getUserId();
    let response = null;
    try {
      response = await API.get(`/ticket/assignQueueMembers/${userId}`, {
        params: {},
        headers: { Authorization: "Bearer: " + token },
      });
    } catch (e) {
      response = e;
    }
    return handleResponse(response);
  },
};

export default admin;
