import * as React from 'react';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import Button from '@mui/material/Button';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import InventoryOutlinedIcon from '@mui/icons-material/InventoryOutlined';
import { Payment } from "@mui/icons-material";
import NotificationsNoneOutlinedIcon from '@mui/icons-material/NotificationsNoneOutlined';
import PlaylistAddCheckIcon from '@mui/icons-material/PlaylistAddCheck';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import FolderOutlinedIcon from '@mui/icons-material/FolderOutlined';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import MenuIcon from '@mui/icons-material/Menu';
import { AppBar, Avatar, IconButton, Menu, MenuItem, Toolbar, Tooltip, Typography } from '@mui/material';
import { Logout } from '@mui/icons-material';
import { getRole, getUserName } from "../../src/services/api/utils";
import { logOut } from "../../src/services/helpers";
import { useEffect } from 'react';
import { useState } from 'react';

export default function Sidebar() {
    const navigate = useNavigate();
    const [role, setRole] = useState(null);
    const [userName, setUserName] = useState(null);
    console.log("userName--------==========", userName);

    const fetchedRole = getRole();
    const fetchedName = getUserName();

    useEffect(() => {
        if (fetchedName) {
            setUserName(fetchedName);
        }
    }, [fetchedName]);

    useEffect(() => {
        if (fetchedRole) {
            setRole(fetchedRole);
        }
    }, [fetchedRole]);

    const handelLogout = () => {
        logOut();
        navigate("/login");
    };

    const drawerWidth = 240;
    const location = useLocation();

    const [open, setOpen] = React.useState(false);

    const toggleDrawer = (newOpen) => () => {
        setOpen(newOpen);
    };

    const superAdminMenu = [
        {
            name: "All Admins",
            icon: <InventoryOutlinedIcon />,
            path: "/superadmin/dashboard",
        },
        {
            name: "View Reports",
            icon: <InventoryOutlinedIcon />,
            path: "/superadmin/reports",
        },
        {
            name: "View Payments",
            icon: <Payment />,
            path: "/superadmin/payment_report",
        },
    ];

    const adminMenu = [
        {
            name: 'Dashboard',
            icon: <InventoryOutlinedIcon />,
            path: '/admin/dashboard',
        },
        {
            name: 'Sessions',
            icon: <InventoryOutlinedIcon />,
            path: '/admin/sessions',
        },
        {
            name: 'Lot-Levels',
            icon: <NotificationsNoneOutlinedIcon />,
            path: '/admin/lotLevels',
        },
        {
            name: 'Queues',
            icon: <PlaylistAddCheckIcon />,
            path: '/admin/queues',
        },
        {
            name: 'Queue Owners',
            icon: <FolderOutlinedIcon />,
            path: '/admin/queueOwners',
        },
        {
            name: 'Queue Members',
            icon: <PlaylistAddCheckIcon />,
            path: '/admin/queueMembers',
        },
        {
            name: 'Rebalance Queues',
            icon: <FolderOutlinedIcon />,
            path: '/admin/rebalanceQueues',
        },
        {
            name: 'Profile',
            icon: <FolderOutlinedIcon />,
            path: '/admin/profile',
        },
    ]

    const [anchorEl, setAnchorEl] = React.useState(null);
    const opens = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    const DrawerList = (
        <>
            <Box sx={{ overflow: 'auto' }} role="presentation" onClick={toggleDrawer(false)}>
                <div className='flex justify-center items-center mt-[20px] mb-[20px]'>
                    {/* <div className='bg-white text-black rounded-full h-[50px] w-[50px] flex justify-center items-center text-[13px] font-[500]'>LOGO</div>
                    <div className='text-[18px] font-[500] text-white'>QMS</div> */}
                    <img className='h-[80px] w-[80px] rounded-[50%]' src="/assets/images/play_store_512.png" alt="" />
                </div>
                {role === "admin" &&
                    <List>
                        {adminMenu.map((item, index) => (
                            <Link key={index} to={item.path}
                                className=""
                            >
                                <ListItem key={index} disablePadding>
                                    <ListItemButton>
                                        <ListItemIcon className=''>
                                            {item.icon}
                                        </ListItemIcon>
                                        <ListItemText primary={item.name} />
                                    </ListItemButton>
                                </ListItem>
                            </Link>
                        ))}
                    </List>
                }
                {role === "super_admin" &&
                    <List>
                        {superAdminMenu.map((item, index) => (
                            <Link key={index} to={item.path}
                                className=""
                            >
                                <ListItem key={index} disablePadding>
                                    <ListItemButton>
                                        <ListItemIcon className=''>
                                            {item.icon}
                                        </ListItemIcon>
                                        <ListItemText primary={item.name} />
                                    </ListItemButton>
                                </ListItem>
                            </Link>
                        ))}
                    </List>
                }
            </Box>
        </>
    );

    // if (location.pathname === '/' && location.pathname === '/login') {
    //     return null; 
    // }

    return (
        <div>
            <AppBar position="fixed" sx={{
                // zIndex: (theme) => theme.zIndex.drawer + 1,
                backgroundColor: 'white',
                color: 'black',
                boxShadow: 'none',
                width: { sm: `calc(100% - ${drawerWidth}px)` },
                ml: { sm: `${drawerWidth}px` },
            }}>
                <Toolbar>
                    <MenuIcon className='cursor-pointer' sx={{ display: { md: 'none' } }} onClick={toggleDrawer(true)} />
                    <React.Fragment>
                        <Box sx={{ display: 'flex', alignItems: 'center', textAlign: 'center', marginLeft: 'auto' }}>
                            <Tooltip title={userName}>
                                <IconButton
                                    onClick={handleClick}
                                    size="small"
                                    sx={{ ml: 2 }}
                                    aria-controls={open ? 'account-menu' : undefined}
                                    aria-haspopup="true"
                                    aria-expanded={open ? 'true' : undefined}
                                >
                                    <div className='ml-auto flex border w-[210px] p-2 rounded-[20px]'>
                                        <div className='mr-2'>
                                            <Avatar sx={{ width: 32, height: 32 }}></Avatar>
                                        </div>
                                        <div style={{ width: "100%" }}>
                                            {userName && userName.length > 12 ? userName.substr(0, 12) + "..." : userName}
                                        </div>
                                        <div className=''>
                                            <ArrowDropDownIcon />
                                        </div>
                                    </div>
                                </IconButton>
                            </Tooltip>
                        </Box>
                        <Menu
                            anchorEl={anchorEl}
                            id="account-menu"
                            open={opens}
                            onClose={handleClose}
                            onClick={handleClose}
                            PaperProps={{
                                elevation: 0,
                                sx: {
                                    overflow: 'visible',
                                    filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                                    mt: 1.5,
                                    '& .MuiAvatar-root': {
                                        width: 32,
                                        height: 32,
                                        ml: -0.5,
                                        mr: 1,
                                    },
                                    '&:before': {
                                        content: '""',
                                        display: 'block',
                                        position: 'absolute',
                                        top: 0,
                                        right: 14,
                                        width: 10,
                                        height: 10,
                                        bgcolor: 'background.paper',
                                        transform: 'translateY(-50%) rotate(45deg)',
                                        zIndex: 0,
                                    },
                                },
                            }}
                            transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                            anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
                        >
                            <button className="btn btn-link nav-item nav-link">
                                <MenuItem onClick={handelLogout}>
                                    <ListItemIcon>
                                        <Logout fontSize="small" />
                                    </ListItemIcon>
                                    Logout
                                </MenuItem>
                            </button>
                        </Menu>
                    </React.Fragment>
                </Toolbar>
            </AppBar>

            <div className="hidden md:block">
                <Drawer className='CustomDrawer'
                    variant="permanent"
                    sx={{
                        width: drawerWidth,
                        flexShrink: 0,
                        [`& .MuiDrawer-paper`]: { width: drawerWidth, boxSizing: 'border-box' },
                    }}
                    open={open}
                    onClose={toggleDrawer(false)}>
                    {DrawerList}
                </Drawer>
            </div>
            <div className="block md:hidden">
                <Drawer className='CustomDrawer'
                    variant="temporary"
                    sx={{
                        width: drawerWidth,
                        flexShrink: 0,
                        [`& .MuiDrawer-paper`]: { width: drawerWidth, boxSizing: 'border-box' },
                    }}
                    open={open}
                    onClose={toggleDrawer(false)}>
                    {DrawerList}
                </Drawer>
            </div>
        </div>
    );
}