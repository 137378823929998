import { handleResponse, API, getToken, getUserId } from "./utils";

const superAdmin = {
  getAllPayment: async () => {
    const token = await getToken();
    let response = null;
    try {
      response = await API.get(`/payment/get/all`, {
        params: {},
        headers: { Authorization: "Bearer: " + token },
      });
    } catch (e) {
      response = e;
    }
    return handleResponse(response);
  },
  getDataForSuperAdmin: async (date) => {
    const token = await getToken();
    let response = null;
    try {
      response = await API.get(`/lot/superAdmin/all/data/count`, {
        params: { date },
        headers: { Authorization: "Bearer: " + token },
      });
    } catch (e) {
      response = e;
    }
    return handleResponse(response);
  },
};

export default superAdmin;
